import { HttpClient } from '@angular/common/http';
import { interval } from 'rxjs';
import { distinctUntilChanged, map, pairwise, shareReplay, startWith, switchMap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { PopupService } from '../core/controls/services/popup.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../core/controls/services/popup.service";
var VersionService = /** @class */ (function () {
    function VersionService(http, popup) {
        var _this = this;
        this.http = http;
        this.popup = popup;
        this.release$ = interval(10 * 60 * 1000).pipe(startWith({}), switchMap(function () { return _this.http.get('./assets/release.json'); }), map(function (it) { return it.release; }), distinctUntilChanged(), shareReplay(1));
        if (environment.production) {
            this.release$
                .pipe(pairwise())
                .subscribe(function () { return _this.popup.alert('ВНИМАНИЕ', 'Произошло обновление системы. Пожалуйста обновите страницу'); });
        }
    }
    VersionService.ngInjectableDef = i0.defineInjectable({ factory: function VersionService_Factory() { return new VersionService(i0.inject(i1.HttpClient), i0.inject(i2.PopupService)); }, token: VersionService, providedIn: "root" });
    return VersionService;
}());
export { VersionService };
