import { CookieService } from "ngx-cookie";
import { Router } from "@angular/router";
import * as i0 from "@angular/core";
import * as i1 from "ngx-cookie";
import * as i2 from "@angular/router";
var TokenService = /** @class */ (function () {
    function TokenService(cookieService, router) {
        this.cookieService = cookieService;
        this.router = router;
    }
    TokenService.prototype.setToken = function (token) {
        var expireDate = new Date();
        expireDate.setFullYear(expireDate.getFullYear() + 1);
        this.cookieService.put('token', token, {
            expires: expireDate
        });
    };
    TokenService.prototype.getToken = function () {
        return this.cookieService.get('token');
    };
    TokenService.prototype.destroy = function () {
        this.cookieService.remove('token');
        window.location.reload();
    };
    TokenService.prototype.userLogout = function () {
        this.cookieService.remove('token');
        this.router.navigate(['/login']);
        window.location.reload();
    };
    TokenService.ngInjectableDef = i0.defineInjectable({ factory: function TokenService_Factory() { return new TokenService(i0.inject(i1.CookieService), i0.inject(i2.Router)); }, token: TokenService, providedIn: "root" });
    return TokenService;
}());
export { TokenService };
