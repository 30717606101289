import {Component} from "@angular/core";
import {ICellRendererAngularComp} from "ag-grid-angular";
import {ICellRendererParams} from "ag-grid";

@Component({
  selector: 'app-cell-button',
  templateUrl: './cell-renderer-button.component.html',
  styleUrls: ['./cell-renderer-button.component.less']
})
export class CellRendererButtonComponent implements ICellRendererAngularComp {
  public params: any;
  public value: any;
  public show: boolean;

  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.value = params.value;

    if (this.value === null) {
      this.show = false;
    } else {
      this.show = true;
    }
  }

  openLink() {
    window.open(this.value);
  }

  refresh(): boolean {
    return false;
  }
}
