import { Subject } from "rxjs/Subject";
import * as i0 from "@angular/core";
import * as i1 from "@angular/platform-browser";
var LoaderService = /** @class */ (function () {
    function LoaderService(document) {
        var _this = this;
        this.document = document;
        this.semaphore = 0;
        this.loaderState = new Subject();
        // this.refreshLoaderState();
        this.loaderState.subscribe(function (state) {
            if (state) {
                _this.document.body.classList.add('global-loader-visible');
            }
            else {
                _this.document.body.classList.remove('global-loader-visible');
            }
        });
    }
    LoaderService.prototype.show = function () {
        this.semaphore++;
        this.refreshLoaderState();
    };
    LoaderService.prototype.hide = function () {
        if (this.semaphore > 0) {
            this.semaphore--;
        }
        this.refreshLoaderState();
        this.setMessage('');
    };
    LoaderService.prototype.refreshLoaderState = function () {
        if (this.semaphore > 0) {
            this.loaderState.next(true);
        }
        else {
            this.loaderState.next(false);
        }
    };
    LoaderService.prototype.setMessage = function (message) {
        var loaderEl = this.document.querySelector('.loader > *');
        if (loaderEl) {
            loaderEl.setAttribute('data-message', message);
        }
    };
    LoaderService.ngInjectableDef = i0.defineInjectable({ factory: function LoaderService_Factory() { return new LoaderService(i0.inject(i1.DOCUMENT)); }, token: LoaderService, providedIn: "root" });
    return LoaderService;
}());
export { LoaderService };
