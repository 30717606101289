var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { NgZone } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../../../environments/environment';
import { TokenService } from '../../authentication/services/token.service';
import { FileObject } from '../../files/classes/file-object';
import { UploadedFile } from '../../files/classes/uploaded-file';
import { ApiError } from '../classes/api-error';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../authentication/services/token.service";
import * as i3 from "ngx-toastr";
var serializeGet = function (obj) {
    var str = [];
    var _loop_1 = function (prop) {
        var value = obj[prop];
        if (typeof value === 'object') {
            if (Array.isArray(value)) {
                prop = prop + '[]';
                value.forEach(function (value) { return str.push(encodeURIComponent(prop) + "=" + encodeURIComponent(value)); });
            }
            else if (value instanceof Date) {
                str.push(encodeURIComponent(prop) + "=" + value.toISOString());
            }
        }
        else if (value) {
            str.push(encodeURIComponent(prop) + "=" + encodeURIComponent(value));
        }
    };
    for (var prop in obj) {
        _loop_1(prop);
    }
    return str.join('&');
};
var ɵ0 = serializeGet;
var ApiService = /** @class */ (function () {
    function ApiService(http, tokenService, toastrService, zone) {
        this.http = http;
        this.tokenService = tokenService;
        this.toastrService = toastrService;
        this.zone = zone;
        this.noShowErrors = [
            3100
        ];
    }
    ApiService.getFullUrl = function (path, url) {
        if (url === void 0) { url = environment.datapointUrl; }
        var urlParts = [];
        var urlParams = {};
        urlParts.push(url.replace(/\/+$/, ''));
        urlParts.push(path.replace(/^\/+/, ''));
        if (environment.XDEBUG_SESSION_ID) {
            urlParams['XDEBUG_SESSION_START'] = environment.XDEBUG_SESSION_ID;
        }
        return urlParts.join('/') + ApiService.getUrlParamsString(urlParams);
    };
    ApiService.getUrlParamsString = function (params) {
        var result = serializeGet(params);
        return result.length
            ? '?' + result
            : '';
    };
    ApiService.parseDataByType = function (data, type) {
        switch (type) {
            case 'collection':
            //                                return {data: data};
            //                                break;
            case 'model':
            case 'chunked':
            case 'simple':
            default:
                return data;
        }
    };
    ApiService.prototype.request = function (path, body, method) {
        var _this = this;
        if (body === void 0) { body = {}; }
        if (method === void 0) { method = 'POST'; }
        var methodHasBody = method === 'POST' || method === 'PUT' || method === 'PATCH';
        var headers = new HttpHeaders({
            'X-Requested-With': 'XMLHttpRequest',
        });
        var token = this.tokenService.getToken();
        if (token) {
            if (body instanceof FormData) {
                body.append('token', token);
            }
            else if (methodHasBody) {
                body['token'] = token;
            }
            headers = headers.append('X-Auth-Token', token);
        }
        return new Promise(function (resolve, reject) {
            var req$ = methodHasBody
                ? _this.http.request(method, ApiService.getFullUrl(path), { body: body, headers: headers })
                : _this.http.request(method, ApiService.getFullUrl(path) + ApiService.getUrlParamsString(body), { headers: headers });
            req$.subscribe(function (result) {
                if (result.status === 'success') {
                    var data = null;
                    if (result.data !== undefined) {
                        data = ApiService.parseDataByType(result.data, result.dataType || 'object');
                    }
                    return resolve(data);
                }
                else if (result.status === 'error') {
                    if (result.error_code !== undefined && result.error_message !== undefined) {
                        return reject(new ApiError(parseInt(result.error_code, 10), result.error_message, result.data || null));
                    }
                }
                return reject();
            }, function (error) {
                return reject(error);
            });
        }).catch(function (error) {
            if (error === void 0) { error = null; }
            if (error instanceof ApiError) {
                switch (error.code) {
                    case 1200:
                        _this.tokenService.destroy();
                        break;
                    case 1100:
                        _this.toastrService.warning(path, 'Доступ запрещён!');
                        break;
                    case 2000:
                        var errorMessage = '';
                        for (var key in error.data) {
                            if (error.data.hasOwnProperty(key)) {
                                errorMessage += error.data[key][0];
                                errorMessage += '<br>';
                            }
                        }
                        _this.toastrService.error(errorMessage, error.message, { closeButton: true, enableHtml: true, timeOut: 30000 });
                        break;
                    default:
                        if (error.code === 6103) {
                            _this.toastrService.error(error.message, 'Ошибка');
                            setTimeout(function () {
                                _this.tokenService.destroy();
                            }, 1500);
                        }
                        else {
                            if (_this.noShowErrors.indexOf(error.code) === -1) {
                                _this.toastrService.error(error.message, 'Ошибка', { closeButton: true });
                            }
                        }
                }
            }
            else if (error instanceof HttpErrorResponse) {
                if (error.status === 0) {
                    _this.toastrService.error('Ошибка подключения');
                }
            }
            return Promise.reject(error);
        });
    };
    ApiService.prototype.requestForDownload = function (path, params) {
        var _this = this;
        params = params || {};
        var headers = new HttpHeaders({
            'Content-Type': 'application/json'
        });
        if (this.tokenService.getToken()) {
            if (params instanceof FormData) {
                params.append('token', this.tokenService.getToken());
            }
            else {
                params['token'] = this.tokenService.getToken();
            }
        }
        return new Promise(function (resolve, reject) {
            return _this.http.request('POST', ApiService.getFullUrl(path), {
                headers: headers,
                body: params,
                responseType: 'arraybuffer',
                observe: 'response'
            }).subscribe(function (response) {
                var contentDispositionHeader = response.headers.get('Content-Disposition');
                if (contentDispositionHeader !== null) {
                    var blob = new Blob([new Uint8Array(response['body'])], {
                        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                    });
                    var filename = contentDispositionHeader.split(';')[1].trim().split('=')[1].replace(/\"/g, '');
                    var downloadUrl = URL.createObjectURL(blob);
                    var link = document.createElement('a');
                    link.href = downloadUrl;
                    link.download = filename;
                    document.body.appendChild(link);
                    link.click();
                    _this.toastrService.success('Загрузка файла успешна', 'Успех');
                    resolve(blob);
                }
                else {
                    var decodeString = String.fromCharCode.apply(null, new Uint8Array(response['body']));
                    var json = JSON.parse(decodeString);
                    return reject(json);
                }
            });
        }).catch(function (err) {
            if (err.error_code === 2000) {
                var errorMessage = '';
                for (var key in err.data) {
                    if (err.data.hasOwnProperty(key)) {
                        errorMessage += err.data[key][0];
                        errorMessage += '<br>';
                    }
                }
                _this.toastrService.error(errorMessage, err.error_message, { enableHtml: true });
            }
        });
    };
    ApiService.prototype.requestForUpload = function (path, file, progress, params) {
        var _this = this;
        params = params || {};
        var fileObjectPromise = null;
        if (file instanceof File) {
            fileObjectPromise = FileObject.createFromFile(file);
        }
        else {
            fileObjectPromise = file;
        }
        return Promise.resolve(fileObjectPromise)
            .then(function (fileObject) {
            return new Promise(function (resolve, reject) {
                var xhr = new XMLHttpRequest();
                xhr.upload.onprogress = function (event) {
                    _this.zone.run(function () {
                        progress(event, event.total, event.loaded);
                    });
                };
                xhr.onload = function () {
                    var result = JSON.parse(xhr.response);
                    if (result['status'] === 'success') {
                        result = result['data'];
                        var fileData = {
                            name: result['basename'] + '.' + result['extension'],
                            guid: result['guid'],
                            date: result['created_at'],
                            size: result['size'],
                            type: result['type'],
                        };
                        resolve(UploadedFile.create(fileData));
                    }
                    else {
                        reject(result);
                    }
                };
                xhr.onerror = function () {
                    var result = JSON.parse(xhr.response);
                    console.log('error', result);
                    reject(result);
                };
                xhr.onabort = function () {
                    var result = JSON.parse(xhr.response);
                    console.log('abort', result);
                    reject(result);
                };
                xhr.open('post', ApiService.getFullUrl(path), true);
                xhr.setRequestHeader('Content-Type', 'application/json');
                xhr.send(JSON.stringify(__assign({ token: _this.tokenService.getToken(), file: fileObject }, params)));
            });
        });
    };
    ApiService.ngInjectableDef = i0.defineInjectable({ factory: function ApiService_Factory() { return new ApiService(i0.inject(i1.HttpClient), i0.inject(i2.TokenService), i0.inject(i3.ToastrService), i0.inject(i0.NgZone)); }, token: ApiService, providedIn: "root" });
    return ApiService;
}());
export { ApiService };
export { ɵ0 };
