var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Router } from "@angular/router";
import { AgGridCellAutoHeight } from "../../classes/ag-grid-cell-auto-height";
var AgGridItemListComponent = /** @class */ (function (_super) {
    __extends(AgGridItemListComponent, _super);
    function AgGridItemListComponent(router) {
        var _this = _super.call(this) || this;
        _this.router = router;
        return _this;
    }
    AgGridItemListComponent.prototype.agInit = function (params) {
        this.params = params;
        this.itemList = params.value;
    };
    AgGridItemListComponent.prototype.onClickItem = function (item) {
        this.router.navigate(item.href);
    };
    return AgGridItemListComponent;
}(AgGridCellAutoHeight));
export { AgGridItemListComponent };
