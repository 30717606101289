import { Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DateService } from '../../system/services/date.service';
import * as _ from 'lodash';

@Injectable()
export class FilterService {
    private filters: any;
    private limit: number;
    private offset: number;
    private defaultFilter: any;

    constructor(private route: ActivatedRoute,
                private router: Router,
                private dateService: DateService) {
        this.filters = {};
        this.limit = 100;
        this.offset = 0;
        this.defaultFilter = {
            limit: this.limit,
            offset: this.offset
        };
    }

    private setFilterFromGlobals(filterModel, filterPath, addYear) {
        // console.log('Set filters from globals')
        // console.log('this.filters', this.filters)
        if (filterModel['year'] === undefined && addYear) {
            filterModel['year'] = this.dateService.getCurrentYear();
        }
        const urlParams = this.route.queryParams['value'];

        // console.log('Url params in filter service', urlParams);
        let params = {};
        //params = _.cloneDeep(urlParams);
        if (urlParams['page'] !== undefined) {
            params['page'] = urlParams['page'];
        }
        for (const prop in this.filters[filterPath]) {
            if (this.filters[filterPath].hasOwnProperty(prop)) {
                filterModel[prop] = this.filters[filterPath][prop];
                const value = JSON.stringify(filterModel[prop]);
                if (value !== '{}' && value !== '[]') {
                    params[prop] = value;
                }
            }
        }

        // added
        if (filterPath === '/placements' && (params['month'] || params['city_id'])) {
            delete this.filters[filterPath]['month'];
            delete this.filters[filterPath]['city_id'];
        }
        // ***

        this.router.navigate([filterPath], {queryParams: params})
            .then(() => {
                //this.setFilterFromUrl(filterModel, filterPath);
            });

    }

    private setFilterFromUrl(filterModel, filterPath, addYear) {
        const params = this.route.queryParams['value'];

        // console.log('Set filters from url params', params);
        let urlParamsFieldNumber = 0;
        for (const p in params) {
            if (params.hasOwnProperty(p)) {
                urlParamsFieldNumber++;
            }
        }
        if (urlParamsFieldNumber < 2) {
            return false;
        }
        for (const prop in params) {
            if (params.hasOwnProperty(prop) && prop !== 'page') {
                const value = params[prop];
                try {
                    filterModel[prop] = JSON.parse(value);
                } catch (e) {
                    filterModel[prop] = value;
                }
            }
        }

        if (filterModel['year'] === undefined && addYear) {
            filterModel['year'] = this.dateService.getCurrentYear();
        }


        this.filters[filterPath] = this.sanitize(filterModel);
        return true;
    }

    private sanitize(filterModel: any) {
        delete filterModel.page;
        delete filterModel.token;
        delete filterModel.limit;
        delete filterModel.offset;

        Object.keys(filterModel).forEach(k => {
            if (filterModel[k] === null) {
                delete filterModel[k];
            }
        });

        return _.cloneDeep(filterModel);
    }

    public updateFilter(filterModel: any, filterPath: string, navigate = true): Promise<boolean> {
        return new Promise((resolve) => {
            this.filters[filterPath] = this.sanitize(filterModel);
            const urlParams = this.route.queryParams['value'];
            const params = {};
            params['page'] = urlParams['page'];

            for (const prop in filterModel) {
                if (filterModel.hasOwnProperty(prop)) {
                    const value = JSON.stringify(filterModel[prop]);
                    if (value !== '{}' && value !== '[]') {
                        params[prop] = value;
                    }
                }
            }
            if (navigate) {
                this.router.navigate([filterPath], {queryParams: params})
                    .then(() => {
                        resolve(true);
                    });
            }
        });

    }

    public setFilter(filterModel: any, filterPath: string, addYear = true) {
        if (this.filters[filterPath] === undefined) {
            this.filters[filterPath] = {};
        }
        if (!this.setFilterFromUrl(filterModel, filterPath, addYear)) {
            this.setFilterFromGlobals(filterModel, filterPath, addYear);
        }

    }

    public getFilter(filterPath: string) {
        if (this.filters[filterPath] === undefined) {
            return false;
        }
        return this.filters[filterPath];
    }
}
