import { ComponentRef, Type, ViewContainerRef } from '@angular/core';

export class WindowHandler<T = any> {
    hostContainerRef: ViewContainerRef;
    index: number;
    componentConstructor: Type<T>;
    componentRef: ComponentRef<T>;
    component: T;

    constructor(hostContainerRef: ViewContainerRef, index: number, componentConstructor: Type<T>, componentRef: ComponentRef<any>) {
        this.hostContainerRef = hostContainerRef;
        this.index = index;
        this.componentConstructor = componentConstructor;
        this.componentRef = componentRef;
        this.component = componentRef.instance;
    }

    public destroy() {
        if (this.hostContainerRef.indexOf(this.componentRef.hostView) === this.index) {
            this.hostContainerRef.remove(this.index);
        }
    }
}
