import {Injectable} from '@angular/core';
import {DialogComponent} from "../components/dialog/dialog.component";
import {AlertComponent} from "../components/alert/alert.component";
import {WindowsService} from "../components/windows/windows.service";
import {WindowHandler} from "../components/windows/window-handler";

@Injectable()
export class PopupService {

    constructor(private windowsService: WindowsService) {
    }

    public dialog(title, message, onConfirm: () => any, onReject?: () => any): WindowHandler {
        const windowHandler = this.windowsService.create(DialogComponent);
        windowHandler.component['title'] = title;
        windowHandler.component['message'] = message;
        windowHandler.component['onReject'].subscribe(() => {
            windowHandler.destroy();
            if (typeof onReject === 'function') {
                onReject();
            }
        });
        windowHandler.component['onConfirm'].subscribe(() => {
            windowHandler.destroy();
            if (typeof onConfirm === 'function') {
                onConfirm();
            }
        });
        return windowHandler;
    }

    public alert(title, message, onConfirm?: () => any): WindowHandler {
        const windowHandler = this.windowsService.create(AlertComponent);
        windowHandler.component['title'] = title;
        windowHandler.component['message'] = message;
        windowHandler.component['onClose'].subscribe(() => {
            windowHandler.destroy();
        });
        windowHandler.component['onConfirm'].subscribe(() => {
            windowHandler.destroy();
            if (onConfirm && typeof onConfirm === 'function') {
                onConfirm();
            }
        });
        return windowHandler;
    }
}
