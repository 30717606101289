export class Privilege {
    id: number;
    name: string;
    description: string;
    alias: string;
    //action_ids: number[];

    public static create(data: { id, name, description, alias }) { //action_ids?: any[]
        const privilege = new Privilege();
        privilege.id = parseInt(data.id, 10);
        privilege.name = data.name;
        privilege.description = data.description;
        privilege.alias = data.alias;
        // if (data.action_ids && data.action_ids instanceof Array) {
        //     privilege.action_ids = data.action_ids.map((id) => parseInt(id, 10));
        // }
        return privilege;
    }
}
