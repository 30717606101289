import { EventEmitter, OnInit } from '@angular/core';
var AlertComponent = /** @class */ (function () {
    function AlertComponent() {
        this.onClose = new EventEmitter();
        this.onConfirm = new EventEmitter();
    }
    Object.defineProperty(AlertComponent.prototype, "message", {
        get: function () {
            return this._message;
        },
        set: function (message) {
            if (message instanceof Array) {
                this._message = message.join('<br/>');
            }
            else {
                this._message = message;
            }
        },
        enumerable: true,
        configurable: true
    });
    AlertComponent.prototype.ngOnInit = function () {
    };
    AlertComponent.prototype.reject = function () {
        this.onClose.emit();
    };
    AlertComponent.prototype.confirm = function () {
        this.onConfirm.emit();
    };
    return AlertComponent;
}());
export { AlertComponent };
