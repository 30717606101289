export class AgGridRowAction {
    action: any;
    params: any;
    paramsFactory: (rowData: any, action, params) => { text?, icon? };

    constructor(action, params: { text?, icon? } | ((rowData: any, action, params) => { text?, icon? })) {
        this.action = action;
        if (typeof params === 'function') {
            this.paramsFactory = params;
        } else {
            this.params = params;
        }
    }
}
