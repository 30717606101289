import {Injectable} from '@angular/core';
import {ApiService} from "../../system/services/api.service";
import {TokenService} from "../../authentication/services/token.service";
import { PrivilegeService } from "../../access-control/services/privilege.service";

@Injectable({ providedIn: 'root' })
export class LoginService {

    constructor(protected apiService: ApiService,
                private tokenService: TokenService,
                private privilegeService: PrivilegeService) {
    }

    async login(login, password, remember) {
        const params = {
            "login": login,
            "password": password
        };
        if (remember) {
            params['remember'] = true;
        }
        const authRequest = await this.apiService.request('/auth/authenticate_user', params);
        this.tokenService.setToken(authRequest['token']);
        await this.privilegeService.getAllowedPrivilegeList();

        return authRequest;
    }

    loginByToken(authToken) {
        return this.apiService.request('/auth/login_by_temporary_token', {
            "temporary_token": authToken
        });
    }

    logout() {
        this.tokenService.userLogout();

        // return this.apiService.request('logout')
        //     .then(() => {
        //         this.tokenService.destroy();
        //     });
    }

    resetPassword(email) {
        return this.apiService.request('/auth/reset_password', {email: email});
    }
}
