import {Component, HostBinding, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-loader',
    templateUrl: './loader.component.html',
    styleUrls: ['./loader.component.less']
})
export class LoaderComponent implements OnInit {
    @Input() view: 'circle' | 'dots' | 'bricks' = 'circle';
    @Input() type: 'inline' | 'relative' | 'fixed' = 'relative';
    @HostBinding('class') hostClass: string;

    constructor() {
    }

    ngOnInit() {
        this.hostClass = ['type-' + this.type, 'view-' + this.view].join(' ');
    }

}
