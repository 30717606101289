import { AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { LoaderService } from "../../modules/core/system/services/loader.service";
import * as $ from 'jquery';
import { environment } from "../../../environments/environment";

declare var require: any;

(window as any).jQuery = $;
require('@fancyapps/fancybox/dist/jquery.fancybox.js');

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.less'],
    encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit, AfterViewInit {
    public loaderVisible = false;

    constructor(private loaderService: LoaderService,
                private cdRef: ChangeDetectorRef) {
    }

    ngOnInit(): void {
        this.loaderService.loaderState.subscribe((result: boolean) => {
            this.loaderVisible = result;
            this.cdRef.detectChanges();
        });
    }

    ngAfterViewInit() {
        $().fancybox({
            selector: '[data-fancybox="images"]',
            onInit: function () {
                $('body').removeClass('fancybox-active');
            },
            animationEffect: 'fade'
        });
    }
}
