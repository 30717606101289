import { Injectable } from '@angular/core';
import { CanActivate, CanLoad, Router } from '@angular/router';
import { TokenService } from "../services/token.service";
import { PrivilegeService } from "../../access-control/services/privilege.service";
import { LoginService } from "../../login/services/login.service";
import { environment } from "../../../../../environments/environment";
import { parse, stringify } from "query-string";

@Injectable({ providedIn: 'root' })
export class AuthenticationComponentGuard implements CanActivate, CanLoad {
    private navigateTo: string;
    constructor(private tokenService: TokenService,
                private privilegeService: PrivilegeService,
                private loginService: LoginService,
                private router: Router) {

    }

    async canActivate(): Promise<boolean> {
        const loggedIn = await this.isLoggedIn();
        if (!loggedIn) {
            this.saveRouteForRedirect();
            this.router.navigate(['/', 'login']);
            return false;
        } else if (this.navigateTo) {
            this.redirectToTheSavedRoute();
        }
        await this.privilegeService.loadAllowedPrivileges();
        return true;
    }

    canLoad(): Promise<boolean> {
        return this.canActivate();
    }

    private redirectToTheSavedRoute() {
        let href = '';
        href += window.location.protocol + '//';
        href += window.location.host;
        window.location.href = href + this.navigateTo;
    }

    private saveRouteForRedirect() {
        let navigateTo = '';
        const pathname = window.location.pathname;
        const search = window.location.search;
        const hash = window.location.hash;
        if (pathname) {
            navigateTo += pathname;
        }
        if (search) {
            const queryParams = parse(location.search) as object;
            delete queryParams[environment.AUTOLOGIN_TOKEN_NAME];
            if (Object.keys(queryParams).length !== 0) {
                navigateTo += `?${stringify(queryParams)}`;
            }
        }
        if (hash) {
            navigateTo += `#${hash}`;
        }
        this.navigateTo = navigateTo;
    }

    private async isLoggedIn() {
        const tempToken = parse(location.search)[environment.AUTOLOGIN_TOKEN_NAME];
        const loggedIn = !!this.tokenService.getToken();
        if (!!tempToken) {
            try {
                const res = await this.loginService.loginByToken(tempToken);
                const token = res['token'];
                if (token) {
                    this.tokenService.setToken(token);
                    this.saveRouteForRedirect();
                    return true;
                }
            } catch (e) {}
        }
        if (loggedIn) {
            return true;
        }
        return false;
    }
}
