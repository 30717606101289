import { ComponentFactoryResolver, Injectable, Injector, Type } from '@angular/core';
import { WindowHandler } from "./window-handler";

@Injectable()
export class WindowsService {
    private windowHandlers = [];

    constructor(private componentFactoryResolver: ComponentFactoryResolver, private injector: Injector) {}

    public create<T>(component: Type<T>) {
        const hostContainerRef = this.injector.get(WindowsInitializerService).getHostContainerRef();

        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(component);
        const componentRef = hostContainerRef.createComponent(componentFactory);
        const viewRef = componentRef.hostView;
        const componentIndex = hostContainerRef.indexOf(viewRef);
        const windowHandler = new WindowHandler(hostContainerRef, componentIndex, component, componentRef);
        this.windowHandlers.push(windowHandler);
        return windowHandler;
    }
}

@Injectable()
export class WindowsInitializerService {
    private  hostContainerRef;
    constructor() {}
    public setHostContainerRef(hostContainerRef) {
        this.hostContainerRef = hostContainerRef;
    }
    public getHostContainerRef() {
        return this.hostContainerRef;
    }
}
