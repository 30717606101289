export class ApiError {
    code: number;
    message: string;
    data: any;

    public static create(data: { code, message, data }) {
        return new ApiError(parseInt(data.code, 10), data.message, data.data || null);
    }

    constructor(code: number, message: string, data: any) {
        this.code = code;
        this.message = message;
        this.data = data;
    }
}
