/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./ag-grid-row-actions.component.less.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./ag-grid-row-actions.component";
var styles_AgGridRowActionsComponent = [i0.styles];
var RenderType_AgGridRowActionsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AgGridRowActionsComponent, data: {} });
export { RenderType_AgGridRowActionsComponent as RenderType_AgGridRowActionsComponent };
function View_AgGridRowActionsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "icon"]], null, null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null)], function (_ck, _v) { var currVal_0 = "icon"; var currVal_1 = _v.parent.context.$implicit.params.icon; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_AgGridRowActionsComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "text"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.params.text; _ck(_v, 1, 0, currVal_0); }); }
function View_AgGridRowActionsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "span", [["class", "action"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.callAction(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AgGridRowActionsComponent_2)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AgGridRowActionsComponent_3)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_0 = _v.context.$implicit.params.icon; _ck(_v, 3, 0, currVal_0); var currVal_1 = _v.context.$implicit.params.text; _ck(_v, 5, 0, currVal_1); }, null); }
export function View_AgGridRowActionsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_AgGridRowActionsComponent_1)), i1.ɵdid(1, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.actions; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_AgGridRowActionsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-ag-grid-row-actions", [], null, null, null, View_AgGridRowActionsComponent_0, RenderType_AgGridRowActionsComponent)), i1.ɵdid(1, 114688, null, 0, i3.AgGridRowActionsComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AgGridRowActionsComponentNgFactory = i1.ɵccf("app-ag-grid-row-actions", i3.AgGridRowActionsComponent, View_AgGridRowActionsComponent_Host_0, {}, {}, []);
export { AgGridRowActionsComponentNgFactory as AgGridRowActionsComponentNgFactory };
