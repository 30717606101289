import {Component} from '@angular/core';
import {Router} from "@angular/router";
import {AgGridCellAutoHeight} from "../../classes/ag-grid-cell-auto-height";

@Component({
    selector: 'app-ag-grid-item-list',
    templateUrl: './ag-grid-item-list.component.html',
    styleUrls: ['./ag-grid-item-list.component.less']
})
export class AgGridItemListComponent extends AgGridCellAutoHeight {

    public itemList: { label: string, href: string };

    constructor(private router: Router) {
        super();
    }

    agInit(params: any) {
        this.params = params;
        this.itemList = params.value;
    }

    public onClickItem(item: any) {
        this.router.navigate(item.href);
    }
}
