var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FileInfo } from "./file-info";
import { Helper } from "../../controls/classes/helper";
var UploadedFile = /** @class */ (function (_super) {
    __extends(UploadedFile, _super);
    function UploadedFile() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    UploadedFile.create = function (data) {
        var uploadedFile = new UploadedFile();
        uploadedFile.name = data.name;
        uploadedFile.size = data.size;
        uploadedFile.type = data.type;
        uploadedFile.guid = data.guid;
        uploadedFile.date = Helper.fromApiDateTime(data.date);
        return uploadedFile;
    };
    return UploadedFile;
}(FileInfo));
export { UploadedFile };
