import {Component, EventEmitter, HostListener, Input, OnInit, Output} from '@angular/core';

@Component({
    selector: 'app-modal',
    templateUrl: './modal.component.html',
    styleUrls: ['./modal.component.less']
})
export class ModalComponent implements OnInit {
    @Output() onCloseModal = new EventEmitter();
    @Input() closeIfOverlayClick = true;
    @HostListener('document:keydown', ['$event'])
    handleEvent(event) {
      if (event.keyCode === 27) {
        this.closeModal();
      }
    }

    constructor() {
    }

    ngOnInit() {
    }

    closeModal() {
        if (this.closeIfOverlayClick) {
            this.onCloseModal.emit();
        }
    }

}
