import {FileInfo} from "./file-info";

export class FileObject extends FileInfo {
    content: string;

    public static create(data: { name, filename, size, type, content }): FileObject {
        const fileObject = new FileObject();
        fileObject.name = data.name || data.filename;
        fileObject.size = data.size;
        fileObject.type = data.type;
        fileObject.content = data.content;
        return fileObject;
    }

    public static createFromFile(file: File): Promise<FileObject> {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = function (event: any) {
                const fileObject: any = {};
                fileObject.content = event.target.result.replace(/^.*;base64,/, '');
                fileObject.name = file.name;
                fileObject.size = file.size;
                fileObject.type = file.type;

                resolve(FileObject.create({
                    content: event.target.result.replace(/^.*;base64,/, ''),
                    name: file.name,
                    filename: file.name,
                    size: file.size,
                    type: file.type
                }));
            };
            reader.onerror = function (event) {
                reject(event);
            };
            reader.readAsDataURL(file);
        });
    }
}
