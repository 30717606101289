import {Component} from '@angular/core';
import {ICellEditorAngularComp} from "ag-grid-angular";
import {AgGridRowAction} from "../../../../../../core/controls/components/ag-grid-row-actions/ag-grid-row-action";
import {PlacementsRow} from "../../../../classes/placements-row";

@Component({
  selector: 'app-cell-editor-save',
  templateUrl: './cell-editor-save.component.html',
  styleUrls: ['./cell-editor-save.component.less']
})
export class CellEditorSaveComponent implements ICellEditorAngularComp {

  private params: any;
  public actions: AgGridRowAction[];
  private gridApi: any;
  public data: PlacementsRow;

  constructor() {
  }

  agInit(params) {
    this.params = params;
    this.gridApi = params.api;
    this.actions = [
      new AgGridRowAction(() => {
        this.gridApi.stopEditing();
      }, {icon: 'fa fa-save'}),
      new AgGridRowAction(() => {
        if (this.params.node.data instanceof PlacementsRow) {
          this.gridApi.stopEditing(true);
          const placementsContext = this.params.context();
          placementsContext['editableRow'] = false;
        } else {
          this.gridApi.setPinnedTopRowData([]);
        }
      }, {icon: 'fa fa-ban'})
    ];
    this.actions.forEach((action: AgGridRowAction) => {
      this.callParamsFactory(action);
    });
  }

  public callAction(action: AgGridRowAction) {
    const actionResult = action.action(this.params.data, action, this.params);
    if (actionResult instanceof Promise) {
      actionResult.then(() => {
        this.callParamsFactory(action);
      });
    } else {
      this.callParamsFactory(action);
    }
  }

  private callParamsFactory(action) {
    if (action.paramsFactory) {
      action.params = action.paramsFactory(this.params.data, action, this.params);
    }
  }

  getValue() {
    return this.params.value ;
  }
}
