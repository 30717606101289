import {Component, ElementRef, HostListener, Input, OnInit, ViewChild} from '@angular/core';

@Component({
    selector: 'app-button',
    templateUrl: './button.component.html',
    styleUrls: ['./button.component.less']
})
export class ButtonComponent implements OnInit {

    @Input() iconCss: string;
    @Input() css: string;
    @Input() type: string;
    @ViewChild('submitButton') submitButton: ElementRef;

    @HostListener('click')
    onClick() {

        if (this.submitButton) {
            this.submitButton.nativeElement.click();
        }
    }

    @HostListener('mousedown')
    onMouseDown() {
        this.setClass(this.elRef.nativeElement, 'clicked');
        setTimeout(() => {
            this.removeClass(this.elRef.nativeElement, 'clicked');
        }, 0);
    }

    constructor(private elRef: ElementRef) {
    }

    ngOnInit() {
    }

    private hasClass(element, cssClass) {
        const cssClassArr = element.className.split(' ');
        return cssClassArr.indexOf(cssClass) > -1;
    }

    private setClass(element, cssClass) {
        if (this.hasClass(element, cssClass)) {
            return;
        }
        element.className += ' ' + cssClass;
    }

    private removeClass(element, cssClass) {
        const cssClassArr = element.className.split(' ');
        const possibleIndex = cssClassArr.indexOf(cssClass);
        if (possibleIndex > -1) {
            cssClassArr.splice(possibleIndex, 1);
            element.className = cssClassArr.join(' ');
        }
    }
}
