import {Injectable} from '@angular/core';
import {CookieService} from "ngx-cookie";
import {Router} from "@angular/router";

@Injectable({ providedIn: 'root' })
export class TokenService {
    constructor(protected cookieService: CookieService,
                private router: Router) {
    }

    public setToken(token: string) {
        const expireDate = new Date();
        expireDate.setFullYear(expireDate.getFullYear() + 1);
        this.cookieService.put('token', token, {
            expires: expireDate
        });
    }

    public getToken(): string {
        return this.cookieService.get('token');
    }

    public destroy() {
        this.cookieService.remove('token');
        window.location.reload();
    }

    public userLogout() {
        this.cookieService.remove('token');
        this.router.navigate(['/login']);
        window.location.reload();
    }
}
