import { Injectable } from '@angular/core';
import { GridOptions } from "ag-grid";
import { CellEditorComponent } from "../../../mi-kfc/placements/components/ag-grid-action-components/cell-editor/cell-editor.component";
import { CellRendererButtonComponent } from "../../../mi-kfc/placements/components/ag-grid-action-components/cell-renderer-button/cell-renderer-button.component";
import { CellRendererCheckboxComponent } from "../../../mi-kfc/placements/components/ag-grid-action-components/cell-renderer-checkbox/cell-renderer-checkbox.component";
import { CellEditorPresenceComponent } from "../../../mi-kfc/placements/components/ag-grid-action-components/cell-editor-presence/cell-editor-presence.component";
import { CellEditorSaveComponent } from "../../../mi-kfc/placements/components/ag-grid-action-components/cell-editor-save/cell-editor-save/cell-editor-save.component";
import { CellRendererRouterlinkComponent } from "../../../mi-kfc/plan-fact-report/components/ag-grid-action-components/cell-renderer-routerlink/cell-renderer-routerlink/cell-renderer-routerlink.component";
import { CellEditorAutocompleteComponent } from "../../../mi-kfc/placements/components/ag-grid-action-components/cell-editor-autocomplete/cell-editor-autocomplete.component";
import { CellEditorListboxComponent } from "../../../mi-kfc/placements/components/ag-grid-action-components/cell-editor-listbox/cell-editor-listbox.component";
import { AgCustomFilterComponent } from "../../../mi-kfc/placements/components/ag-grid-action-components/ag-custom-filter/ag-custom-filter.component";

@Injectable()
export class AgGridConfigService {

    constructor() {
    }

    getConfig(config?: any): GridOptions {


        return {
            ...{
                frameworkComponents: {
                  cellEditor: CellEditorComponent,
                  cellEditorAutocomplete: CellEditorAutocompleteComponent,
                  cellEditorPresence: CellEditorPresenceComponent,
                  cellEditorSave: CellEditorSaveComponent,
                  cellEditorListbox: CellEditorListboxComponent,
                  cellRendererButton: CellRendererButtonComponent,
                  cellRendererCheckbox: CellRendererCheckboxComponent,
                  cellRendererRouterLink: CellRendererRouterlinkComponent,
                  customFilter: AgCustomFilterComponent
                },
                suppressClickEdit: true, // запрет на редактирование по клику
                suppressCellSelection: true, // click  вне строки
                suppressPaginationPanel: true, // hide default pagination
                stopEditingWhenGridLosesFocus: false, // focus сетки
                editType: 'fullRow', // вкл. полное редактирование строки
                enableColResize: true,
                enableSorting: true,
                enableFilter: true,
                animateRows: true, // анимация добавления строки
                rowHeight: 38,
                headerHeight: 50,
                minColWidth: 20,
                overlayLoadingTemplate: 'Загрузка...',
                overlayNoRowsTemplate: 'Нет данных для отображения',
                rowSelection: 'multiple',
                columnDefs: [],
                // rowModelType: 'infinite',
            }, ...config
        };
    }
}
