/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./alert.component.less.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../modal/modal.component.ngfactory";
import * as i3 from "../modal/modal.component";
import * as i4 from "../window/window.component.ngfactory";
import * as i5 from "../window/window.component";
import * as i6 from "./alert.component";
var styles_AlertComponent = [i0.styles];
var RenderType_AlertComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AlertComponent, data: {} });
export { RenderType_AlertComponent as RenderType_AlertComponent };
export function View_AlertComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "app-modal", [], null, [[null, "onCloseModal"], ["document", "keydown"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("document:keydown" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).handleEvent($event) !== false);
        ad = (pd_0 && ad);
    } if (("onCloseModal" === en)) {
        var pd_1 = (_co.reject() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_ModalComponent_0, i2.RenderType_ModalComponent)), i1.ɵdid(1, 114688, null, 0, i3.ModalComponent, [], null, { onCloseModal: "onCloseModal" }), (_l()(), i1.ɵeld(2, 0, null, 0, 2, "app-window", [["iconCss", "fa fa-exclamation"], ["style", "width: 800px"]], null, [[null, "onClose"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onClose" === en)) {
        var pd_0 = (_co.reject() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_WindowComponent_0, i4.RenderType_WindowComponent)), i1.ɵdid(3, 114688, null, 0, i5.WindowComponent, [], { title: [0, "title"], iconCss: [1, "iconCss"] }, { onClose: "onClose" }), (_l()(), i1.ɵeld(4, 0, null, 0, 0, "div", [["class", "message"]], [[8, "innerHTML", 1]], null, null, null, null))], function (_ck, _v) { var _co = _v.component; _ck(_v, 1, 0); var currVal_0 = _co.title; var currVal_1 = "fa fa-exclamation"; _ck(_v, 3, 0, currVal_0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.message; _ck(_v, 4, 0, currVal_2); }); }
export function View_AlertComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-alert", [], null, null, null, View_AlertComponent_0, RenderType_AlertComponent)), i1.ɵdid(1, 114688, null, 0, i6.AlertComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AlertComponentNgFactory = i1.ɵccf("app-alert", i6.AlertComponent, View_AlertComponent_Host_0, { title: "title", message: "message" }, { onClose: "onClose", onConfirm: "onConfirm" }, []);
export { AlertComponentNgFactory as AlertComponentNgFactory };
