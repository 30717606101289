var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import { CellEditorComponent } from "../../../mi-kfc/placements/components/ag-grid-action-components/cell-editor/cell-editor.component";
import { CellRendererButtonComponent } from "../../../mi-kfc/placements/components/ag-grid-action-components/cell-renderer-button/cell-renderer-button.component";
import { CellRendererCheckboxComponent } from "../../../mi-kfc/placements/components/ag-grid-action-components/cell-renderer-checkbox/cell-renderer-checkbox.component";
import { CellEditorPresenceComponent } from "../../../mi-kfc/placements/components/ag-grid-action-components/cell-editor-presence/cell-editor-presence.component";
import { CellEditorSaveComponent } from "../../../mi-kfc/placements/components/ag-grid-action-components/cell-editor-save/cell-editor-save/cell-editor-save.component";
import { CellRendererRouterlinkComponent } from "../../../mi-kfc/plan-fact-report/components/ag-grid-action-components/cell-renderer-routerlink/cell-renderer-routerlink/cell-renderer-routerlink.component";
import { CellEditorAutocompleteComponent } from "../../../mi-kfc/placements/components/ag-grid-action-components/cell-editor-autocomplete/cell-editor-autocomplete.component";
import { CellEditorListboxComponent } from "../../../mi-kfc/placements/components/ag-grid-action-components/cell-editor-listbox/cell-editor-listbox.component";
import { AgCustomFilterComponent } from "../../../mi-kfc/placements/components/ag-grid-action-components/ag-custom-filter/ag-custom-filter.component";
var AgGridConfigService = /** @class */ (function () {
    function AgGridConfigService() {
    }
    AgGridConfigService.prototype.getConfig = function (config) {
        return __assign({
            frameworkComponents: {
                cellEditor: CellEditorComponent,
                cellEditorAutocomplete: CellEditorAutocompleteComponent,
                cellEditorPresence: CellEditorPresenceComponent,
                cellEditorSave: CellEditorSaveComponent,
                cellEditorListbox: CellEditorListboxComponent,
                cellRendererButton: CellRendererButtonComponent,
                cellRendererCheckbox: CellRendererCheckboxComponent,
                cellRendererRouterLink: CellRendererRouterlinkComponent,
                customFilter: AgCustomFilterComponent
            },
            suppressClickEdit: true,
            suppressCellSelection: true,
            suppressPaginationPanel: true,
            stopEditingWhenGridLosesFocus: false,
            editType: 'fullRow',
            enableColResize: true,
            enableSorting: true,
            enableFilter: true,
            animateRows: true,
            rowHeight: 38,
            headerHeight: 50,
            minColWidth: 20,
            overlayLoadingTemplate: 'Загрузка...',
            overlayNoRowsTemplate: 'Нет данных для отображения',
            rowSelection: 'multiple',
            columnDefs: [],
        }, config);
    };
    return AgGridConfigService;
}());
export { AgGridConfigService };
