
export class PlacementsRow {
  address: string;
  budget_type: string;
  budget_type_id: number;
  city: any;
  city_id: number;
  comment: string;
  contractor: any;
  contractor_id: number;
  design_type: string;
  format: string;
  grp: number;
  guid: string;
  has_problem: boolean;
  id: number;
  layout_address: string;
  layout_arrow: any;
  layout_distance: string;
  layout_info: string;
  layout_size: string;
  layout_type: any;
  location: any;
  layout_type_id: number;
  layout_arrow_id: number;
  media_type: any;
  media_type_id: number;
  months: any[];
  partner: any;
  partner_id: number;
  photo: string;
  placement_price: number;
  problem_status: 'active' | 'resolved' | 'inactive';
  presence_apr: number;
  presence_aug: number;
  presence_dec: number;
  presence_feb: number;
  presence_jan: number;
  presence_jul: number;
  presence_jun: number;
  presence_mar: number;
  presence_may: number;
  presence_nov: number;
  presence_oct: number;
  presence_sep: number;
  production_price: number;
  quantity: number;
  reach_program: any;
  reach_program_id: number;
  region: any;
  region_id: number;
  restaurant: any;
  restaurant_id: number;
  side_type: number;
  type_id: number;
  year: number;

  public static create(data: {
    address,
    budget_type,
    budget_type_id,
    city,
    city_id,
    comment,
    contractor,
    contractor_id,
    design_type,
    format,
    grp,
    guid,
    has_problem,
    id,
    layout_address,
    layout_arrow: any,
    layout_distance,
    layout_info,
    layout_size,
    layout_type: any,
    location: any;
    layout_type_id,
    layout_arrow_id,
    media_type: any,
    media_type_id,
    months: any[];
    partner: any,
    partner_id,
    photo,
    problem_status,
    placement_price,
    presence_apr,
    presence_aug,
    presence_dec,
    presence_feb,
    presence_jan,
    presence_jul,
    presence_jun,
    presence_mar,
    presence_may,
    presence_nov,
    presence_oct,
    presence_sep,
    production_price,
    quantity,
    reach_program,
    reach_program_id,
    region,
    region_id,
    restaurant: any[],
    restaurant_id,
    side_type,
    type_id,
    year
  }): PlacementsRow {
    const placementRow = new PlacementsRow();
    placementRow.address = data.address,
    placementRow.budget_type = data.budget_type || {name: null},
    placementRow.budget_type_id = parseInt(data.budget_type_id, 10),
    placementRow.city = data.city || {name: null},
    placementRow.city_id = parseInt(data.city_id, 10),
    placementRow.comment = data.comment,
    placementRow.contractor = data.contractor || {name: null},
    placementRow.contractor_id = parseInt(data.contractor_id, 10),
    placementRow.design_type = data.design_type,
    placementRow.format  = data.format,
    placementRow.grp = data.grp,
    placementRow.guid = data.guid,
    placementRow.has_problem = data.has_problem,
    placementRow.id = parseInt(data.id, 10),
    placementRow.layout_address = data.layout_address,
    placementRow.layout_arrow = data.layout_arrow || {},
    placementRow.layout_distance = data.layout_distance,
    placementRow.layout_info = data.layout_info,
    placementRow.layout_size = data.layout_size,
    placementRow.layout_type = data.layout_type || {},
    placementRow.location = data.location || {},
    placementRow.layout_type_id = parseInt(data.layout_type_id, 10),
    placementRow.layout_arrow_id = parseInt(data.layout_arrow_id, 10),
    placementRow.media_type = data.media_type || {},
    placementRow.media_type_id = parseInt(data.media_type_id, 10),
    placementRow.months = data.months || [],
    placementRow.partner = data.partner || {},
    placementRow.partner_id = data.partner_id,
    placementRow.photo = data.photo,
    placementRow.placement_price = parseFloat(data.placement_price),
    placementRow.problem_status = data.problem_status,
    placementRow.presence_apr = data.presence_apr,
    placementRow.presence_aug = data.presence_aug,
    placementRow.presence_dec = data.presence_dec,
    placementRow.presence_feb = data.presence_feb,
    placementRow.presence_jan = data.presence_jan,
    placementRow.presence_jul = data.presence_jul,
    placementRow.presence_jun = data.presence_jun,
    placementRow.presence_mar = data.presence_mar,
    placementRow.presence_may = data.presence_may,
    placementRow.presence_nov = data.presence_nov,
    placementRow.presence_oct = data.presence_oct,
    placementRow.presence_sep = data.presence_sep,
    placementRow.production_price = parseFloat(data.production_price),
    placementRow.quantity = parseInt(data.quantity, 10),
    placementRow.reach_program = data.reach_program,
    placementRow.reach_program_id = parseInt(data.reach_program_id, 10),
    placementRow.region = data.region || {name: null},
    placementRow.region_id = parseInt(data.region_id, 10),
    placementRow.restaurant = data.restaurant,
    placementRow.restaurant_id = parseInt(data.restaurant_id, 10),
    placementRow.side_type = data.side_type,
    placementRow.type_id = parseInt(data.type_id, 10),
    placementRow.year = data.year;
    return placementRow;
  }
}
