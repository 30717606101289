/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./segment.component.less.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./segment.component";
var styles_SegmentComponent = [i0.styles];
var RenderType_SegmentComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SegmentComponent, data: {} });
export { RenderType_SegmentComponent as RenderType_SegmentComponent };
function View_SegmentComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "span", [["class", "segment-collapse-handler"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleCollapse() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, null); }
function View_SegmentComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [["class", "segment-handler"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [["class", "icon"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onHandlerClick.emit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "icon"; var currVal_1 = _co.handlerCss; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_SegmentComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "segment-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "segment-icon"]], null, null, null, null, null)), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "segment-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "div", [["class", "segment-datalist"]], null, null, null, null, null)), i1.ɵncd(null, 0), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SegmentComponent_2)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SegmentComponent_3)), i1.ɵdid(10, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "segment-icon"; var currVal_1 = _co.iconCss; _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_3 = _co.collapsable; _ck(_v, 8, 0, currVal_3); var currVal_4 = _co.handlerCss; _ck(_v, 10, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.header; _ck(_v, 4, 0, currVal_2); }); }
export function View_SegmentComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "segment-inbox"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SegmentComponent_1)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 3, "div", [["class", "container-fluid segment-content"]], [[2, "content-bg", null]], null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "div", [["class", "col-sm-12"]], null, null, null, null, null)), i1.ɵncd(null, 1)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.login; _ck(_v, 2, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.customCss; _ck(_v, 3, 0, currVal_1); }); }
export function View_SegmentComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-segment", [], [[2, "collapsed", null]], null, null, View_SegmentComponent_0, RenderType_SegmentComponent)), i1.ɵdid(1, 114688, null, 0, i3.SegmentComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).collapsed; _ck(_v, 0, 0, currVal_0); }); }
var SegmentComponentNgFactory = i1.ɵccf("app-segment", i3.SegmentComponent, View_SegmentComponent_Host_0, { header: "header", iconCss: "iconCss", headerCss: "headerCss", collapsable: "collapsable", collapsed: "collapsed", handlerCss: "handlerCss", login: "login", customCss: "customCss", dataList: "dataList" }, { onHandlerClick: "onHandlerClick", onSelected: "onSelected" }, ["[app-segment-handler]", "*"]);
export { SegmentComponentNgFactory as SegmentComponentNgFactory };
