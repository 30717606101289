import {Inject, Injectable} from '@angular/core';
import {Subject} from "rxjs/Subject";
import {DOCUMENT} from "@angular/platform-browser";

@Injectable({ providedIn: 'root' })
export class LoaderService {
    public semaphore = 0;
    public loaderState = new Subject();

    constructor(@Inject(DOCUMENT) private document: Document) {
        // this.refreshLoaderState();
        this.loaderState.subscribe((state) => {
            if (state) {
                this.document.body.classList.add('global-loader-visible');
            } else {
                this.document.body.classList.remove('global-loader-visible');
            }
        });
    }

    show() {
        this.semaphore++;
        this.refreshLoaderState();
    }

    hide() {
        if (this.semaphore > 0) {
            this.semaphore--;
        }
        this.refreshLoaderState();
        this.setMessage('');
    }

    refreshLoaderState() {
        if (this.semaphore > 0) {
            this.loaderState.next(true);
        } else {
            this.loaderState.next(false);
        }
    }
    setMessage(message: string) {
        const loaderEl = this.document.querySelector('.loader > *');
        if (loaderEl) {
            loaderEl.setAttribute('data-message', message);
        }
    }
}
