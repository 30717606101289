<div class="segment-inbox">
    <div class="segment-header" *ngIf="!login">
        <div class="segment-icon" [ngClass]="iconCss"></div>
        <div class="segment-title">{{header}}</div>
        <div class="segment-datalist">
            <ng-content select="[app-segment-handler]"></ng-content>
        </div>
        <span *ngIf="collapsable" class="segment-collapse-handler" (click)="toggleCollapse()"></span>
        <span *ngIf="handlerCss" class="segment-handler"><span class="icon" [ngClass]="handlerCss" (click)="onHandlerClick.emit()"></span></span>
    </div>
    <div class="container-fluid segment-content" [class.content-bg]="customCss">
        <div class="row">
            <div class="col-sm-12">
                <ng-content></ng-content>
            </div>
        </div>
    </div>
</div>
