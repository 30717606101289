import {Component, OnInit} from '@angular/core';
import {AgGridRowAction} from "./ag-grid-row-action";
import {ColDef} from "ag-grid";
import * as _ from "lodash";

@Component({
    selector: 'app-ag-grid-row-actions',
    templateUrl: './ag-grid-row-actions.component.html',
    styleUrls: ['./ag-grid-row-actions.component.less']
})
export class AgGridRowActionsComponent implements OnInit {

    private agData: any;
    private colDef: ColDef;
    public actions: AgGridRowAction[];

    constructor() {
    }

    ngOnInit() {
    }

    public agInit(agData) {
        this.colDef = agData.colDef;
        this.colDef.suppressKeyboardEvent = () => {
           return true;
        };

        this.agData = agData;
        this.actions = _.cloneDeep(this.agData.value || []);
        this.actions.forEach((action: AgGridRowAction) => {
            this.callParamsFactory(action);
        });
    }

    public callAction(action: AgGridRowAction) {
        const actionResult = action.action(this.agData.data, action, this.agData);
        if (actionResult instanceof Promise) {
            actionResult.then(() => {
                this.callParamsFactory(action);
            });
        } else {
            this.callParamsFactory(action);
        }
    }

    private callParamsFactory(action) {
        if (action.paramsFactory) {
            action.params = action.paramsFactory(this.agData.data, action, this.agData);
        }
    }
}
