import { ErrorHandler, Injectable } from '@angular/core';
import { first } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { VersionService } from './version.service';

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
    constructor(private versionHandler: VersionService) {
    }
    handleError(error) {
        Sentry.captureException(error.originalError || error);
        throw error;
    }

    async init() {
        if (environment.production) {
            const release = await this.versionHandler.release$.pipe(first()).toPromise();
            Sentry.init({
                dsn: 'https://c41ebc8b0f6e47b9b74f7e72c2ddf381@sentry.mediainstinctgroup.ru/7',
                release,
            });
        }
    }
}
