import {Component} from "@angular/core";
import {ICellRendererAngularComp} from "ag-grid-angular";
import {ICellRendererParams} from "ag-grid";

@Component({
  selector: 'app-cell-checkbox',
  templateUrl: './cell-renderer-checkbox.component.html',
  styleUrls: ['./cell-renderer-checkbox.component.less']
})
export class CellRendererCheckboxComponent implements ICellRendererAngularComp {

  public params: any;
  public selected: boolean;

  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.selected = this.params.data.selected;
  }

  onChangeModel(event) {
    console.log("CHECKBOX", event);
  }

  refresh(): boolean {
    return false;
  }
}
