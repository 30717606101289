import {Injectable} from '@angular/core';
import {CheckboxComponent} from "./checkbox.component";
import * as _ from "lodash";

@Injectable()
export class CheckboxService {

    private groups: {
        group: string,
        components: CheckboxComponent[]
    }[] = [];

    constructor() {
    }

    public register(checkbox: CheckboxComponent) {
        this.addComponentToGroup(checkbox, checkbox.group);
    }

    public onChange(checkbox: CheckboxComponent) {
        if (!checkbox.group) {
            return false;
        }
        if (checkbox.master) {
            const components = this.getGroupComponents(checkbox.group);
            components.forEach((sibCheckbox: CheckboxComponent) => {
                if (checkbox !== sibCheckbox) {
                    sibCheckbox.setChecked(checkbox.checked);
                }
            });
        } else {
            const components = this.getGroupComponents(checkbox.group);
            let allSlaveChecked = true;
            for (let i = 0; i < components.length; i++) {
                if (!components[i].master && !components[i].checked) {
                    allSlaveChecked = false;
                    break;
                }
            }
            components.forEach((sibCheckbox: CheckboxComponent) => {
                if (sibCheckbox.master) {
                    sibCheckbox.setChecked(allSlaveChecked);
                }
            });
        }
    }

    private getGroupComponents(group: string): CheckboxComponent[] {
        const groupObject = _.find(this.groups, {group: group});
        if (groupObject) {
            return groupObject.components;
        }
        throw new Error('Group "' + group + '" not registered!');
    }

    private addComponentToGroup(checkbox: CheckboxComponent, group: string) {
        let groupObject = _.find(this.groups, {group: group});
        if (!groupObject) {
            groupObject = {
                group: group,
                components: []
            };
            this.groups.push(groupObject);
        }
        groupObject.components.push(checkbox);
    }
}
