import { Injectable } from '@angular/core';
import { Privilege } from "../classes/privilege";
import { ApiService } from "../../system/services/api.service";
import * as _ from "lodash";

@Injectable({ providedIn: 'root' })
export class PrivilegeService {
    private allowedPrivilegeList: Privilege[];
    private allowedPrivilegeListIndexedByAlias: {};
    private rootAccess: boolean;

    constructor(private apiService: ApiService) {}

    public getAllowedPrivilegeList(): Promise<Privilege[]> {
        return this.apiService.request('/access/get_allowed_privilege_list')
            .then((privilegeSrcList: any[]) => privilegeSrcList.map((privilegeSrc) => this.privilegeFromApi(privilegeSrc)));
    }

    public loadAllowedPrivileges() {
        return this.isRootAccess()
            .then(() => {
                if (!this.rootAccess) {
                    if (this.allowedPrivilegeList && this.allowedPrivilegeList.length) {
                        return Promise.resolve(this.allowedPrivilegeList);
                    }
                    return this.getAllowedPrivilegeList()
                        .then((privilegeList: Privilege[]) => {
                            this.allowedPrivilegeList = privilegeList;
                            this.allowedPrivilegeListIndexedByAlias = _.keyBy(privilegeList, 'alias');
                        });
                }
            });
    }

    private isRootAccess(): any {
        if (this.rootAccess !== undefined) {
            return Promise.resolve(this.rootAccess);
        }
        return this.apiService.request('access/is_root_access')
            .then((rootAccess: any) => {
                this.rootAccess = !!rootAccess;
                return this.rootAccess;
            });
    }

    public privilegeAllowed(alias: string | string[]): boolean {
        if (this.rootAccess) {
            return true;
        }
        if (!(alias instanceof Array)) {
            alias = [alias];
        }
        for (let i = 0; i < alias.length; i++) {
            if (this.allowedPrivilegeListIndexedByAlias[alias[i]] && this.allowedPrivilegeListIndexedByAlias[alias[i]] instanceof Privilege) {
                return true;
            }
        }
        return false;
    }

    public getPrivilege(id: number): Promise<Privilege> {
        return this.apiService.request('get_privilege', {id: id})
            .then((privilegeSrc: any) => this.privilegeFromApi(privilegeSrc));
    }

    public getPrivilegeList() {
        return this.apiService.request('access/get_privilege_list')
            .then((privilegeSrcList: any) => {
                return privilegeSrcList.data.map((privilegeSrc: any) => this.privilegeFromApi(privilegeSrc));
            });
    }

    private createPrivilege(privilege: Privilege) {
        return this.apiService.request('create_privilege', this.privilegeToApi(privilege))
            .then((privilegeSrc: any) => this.privilegeFromApi(privilegeSrc));
    }

    private updatePrivilege(privilege: Privilege) {
        return this.apiService.request('update_privilege', this.privilegeToApi(privilege))
            .then((privilegeSrc: any) => this.privilegeFromApi(privilegeSrc));
    }

    public savePrivilege(privilege: Privilege) {
        if (privilege.id > 0) {
            return this.updatePrivilege(privilege);
        }
        return this.createPrivilege(privilege);
    }

    public deletePrivilege(id: number) {
        return this.apiService.request('delete_privilege', {id: id});
    }

    private privilegeToApi(privilege: Privilege) {
        const params = _.cloneDeep(privilege);
        return params;
    }

    private privilegeFromApi(data: any): Privilege {
        return Privilege.create(data);
    }

}
