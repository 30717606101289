var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { AgGridCellAutoHeight } from "../../classes/ag-grid-cell-auto-height";
var AgGridHtmlCellComponent = /** @class */ (function (_super) {
    __extends(AgGridHtmlCellComponent, _super);
    function AgGridHtmlCellComponent() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    AgGridHtmlCellComponent.prototype.agInit = function (params) {
        _super.prototype.agInit.call(this, params);
        this.htmlContent = params.value;
    };
    return AgGridHtmlCellComponent;
}(AgGridCellAutoHeight));
export { AgGridHtmlCellComponent };
