import {AfterViewChecked} from "@angular/core";

export abstract class AgGridCellAutoHeight implements AfterViewChecked {
    protected params: any;

    public agInit(params) {
        this.params = params;
    }

    ngAfterViewChecked() {

        if (this.params.eGridCell && this.params.eGridCell.lastChild) {
            const height = this.params.eGridCell.lastChild.offsetHeight + 30;
            if (height > this.params.node.rowHeight) {
                this.params.node.setRowHeight(height);
                this.params.api.onRowHeightChanged();
            }
        }
    }
}
