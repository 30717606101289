// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --configuration=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
    production: true,
    testEnvironment: false,
    domain: 'kfc.media/',
    datapointUrl: '/api',
    DATE_FORMAT_API: 'YYYY-MM-DD',
    DATETIME_FORMAT_API: 'YYYY-MM-DD HH:mm:ss',
    DATE_FORMAT: 'dd.MM.y',
    DATETIME_FORMAT: 'dd.MM.y HH:mm',
    AUTOLOGIN_TOKEN_NAME: 'temporary_token',
    XDEBUG_SESSION_ID: null
};
