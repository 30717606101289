var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = y[op[0] & 2 ? "return" : op[0] ? "throw" : "next"]) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [0, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Router } from '@angular/router';
import { TokenService } from "../services/token.service";
import { PrivilegeService } from "../../access-control/services/privilege.service";
import { LoginService } from "../../login/services/login.service";
import { environment } from "../../../../../environments/environment";
import { parse, stringify } from "query-string";
import * as i0 from "@angular/core";
import * as i1 from "../services/token.service";
import * as i2 from "../../access-control/services/privilege.service";
import * as i3 from "../../login/services/login.service";
import * as i4 from "@angular/router";
var AuthenticationComponentGuard = /** @class */ (function () {
    function AuthenticationComponentGuard(tokenService, privilegeService, loginService, router) {
        this.tokenService = tokenService;
        this.privilegeService = privilegeService;
        this.loginService = loginService;
        this.router = router;
    }
    AuthenticationComponentGuard.prototype.canActivate = function () {
        return __awaiter(this, void 0, void 0, function () {
            var loggedIn;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.isLoggedIn()];
                    case 1:
                        loggedIn = _a.sent();
                        if (!loggedIn) {
                            this.saveRouteForRedirect();
                            this.router.navigate(['/', 'login']);
                            return [2 /*return*/, false];
                        }
                        else if (this.navigateTo) {
                            this.redirectToTheSavedRoute();
                        }
                        return [4 /*yield*/, this.privilegeService.loadAllowedPrivileges()];
                    case 2:
                        _a.sent();
                        return [2 /*return*/, true];
                }
            });
        });
    };
    AuthenticationComponentGuard.prototype.canLoad = function () {
        return this.canActivate();
    };
    AuthenticationComponentGuard.prototype.redirectToTheSavedRoute = function () {
        var href = '';
        href += window.location.protocol + '//';
        href += window.location.host;
        window.location.href = href + this.navigateTo;
    };
    AuthenticationComponentGuard.prototype.saveRouteForRedirect = function () {
        var navigateTo = '';
        var pathname = window.location.pathname;
        var search = window.location.search;
        var hash = window.location.hash;
        if (pathname) {
            navigateTo += pathname;
        }
        if (search) {
            var queryParams = parse(location.search);
            delete queryParams[environment.AUTOLOGIN_TOKEN_NAME];
            if (Object.keys(queryParams).length !== 0) {
                navigateTo += "?" + stringify(queryParams);
            }
        }
        if (hash) {
            navigateTo += "#" + hash;
        }
        this.navigateTo = navigateTo;
    };
    AuthenticationComponentGuard.prototype.isLoggedIn = function () {
        return __awaiter(this, void 0, void 0, function () {
            var tempToken, loggedIn, res, token, e_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        tempToken = parse(location.search)[environment.AUTOLOGIN_TOKEN_NAME];
                        loggedIn = !!this.tokenService.getToken();
                        if (!!!tempToken) return [3 /*break*/, 4];
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.loginService.loginByToken(tempToken)];
                    case 2:
                        res = _a.sent();
                        token = res['token'];
                        if (token) {
                            this.tokenService.setToken(token);
                            this.saveRouteForRedirect();
                            return [2 /*return*/, true];
                        }
                        return [3 /*break*/, 4];
                    case 3:
                        e_1 = _a.sent();
                        return [3 /*break*/, 4];
                    case 4:
                        if (loggedIn) {
                            return [2 /*return*/, true];
                        }
                        return [2 /*return*/, false];
                }
            });
        });
    };
    AuthenticationComponentGuard.ngInjectableDef = i0.defineInjectable({ factory: function AuthenticationComponentGuard_Factory() { return new AuthenticationComponentGuard(i0.inject(i1.TokenService), i0.inject(i2.PrivilegeService), i0.inject(i3.LoginService), i0.inject(i4.Router)); }, token: AuthenticationComponentGuard, providedIn: "root" });
    return AuthenticationComponentGuard;
}());
export { AuthenticationComponentGuard };
