import {FileInfo} from "./file-info";
import {Helper} from "../../controls/classes/helper";

export class UploadedFile extends FileInfo {
    guid: string;
    date: Date;

    public static create(data: { name, size, type, guid, date }): UploadedFile {
        const uploadedFile = new UploadedFile();
        uploadedFile.name = data.name;
        uploadedFile.size = data.size;
        uploadedFile.type = data.type;
        uploadedFile.guid = data.guid;
        uploadedFile.date = Helper.fromApiDateTime(data.date);
        return uploadedFile;
    }
}
