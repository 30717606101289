import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { interval } from 'rxjs';
import { distinctUntilChanged, map, pairwise, shareReplay, startWith, switchMap, tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { PopupService } from '../core/controls/services/popup.service';

@Injectable({providedIn: 'root'})
export class VersionService {
    release$ = interval(10 * 60 * 1000).pipe(
        startWith({}),
        switchMap(() => this.http.get<{ release: string }>('./assets/release.json')),
        map(it => it.release),
        distinctUntilChanged(),
        shareReplay(1),
    );

    constructor(private http: HttpClient, private popup: PopupService) {
        if (environment.production) {
            this.release$
                .pipe(pairwise())
                .subscribe(() => this.popup.alert(
                    'ВНИМАНИЕ',
                    'Произошло обновление системы. Пожалуйста обновите страницу'
                ));
        }
    }
}
