import { NgModule } from '@angular/core';

import { WindowsHostDirective } from './windows-host.directive';
import { WindowsInitializerService } from "./windows.service";

@NgModule({
    providers: [WindowsInitializerService],
    declarations: [WindowsHostDirective],
    exports: [WindowsHostDirective],
})
export class WindowsHostModule {
}
