import { Directive, ViewContainerRef } from '@angular/core';
import { WindowsInitializerService } from "./windows.service";

@Directive({
    selector: '[appWindowsHost]'
})
export class WindowsHostDirective {

    constructor(private windowsInitializerService: WindowsInitializerService, private viewContainerRef: ViewContainerRef) {
        windowsInitializerService.setHostContainerRef(viewContainerRef);
    }

}
