import {AfterViewInit, Component, ViewChild} from '@angular/core';
import {ICellEditorAngularComp} from "ag-grid-angular";
import {PlacementsService} from "../../../services/placements.service";
import {ICellEditorParams} from "ag-grid";
import {PrivilegeService} from "../../../../../core/access-control/services/privilege.service";

@Component({
  selector: 'app-cell-editor-autocomplete',
  templateUrl: './cell-editor-autocomplete.component.html',
  styleUrls: ['./cell-editor-autocomplete.component.less']
})
export class CellEditorAutocompleteComponent implements ICellEditorAngularComp, AfterViewInit {
    public params: any;
    public currentValue: any;
    public url: string;
    public disabled: boolean;
    public privilege: string;

    public invalid: boolean;
    @ViewChild('container') autocompleteElemRef: any;

    constructor(private placementService: PlacementsService,
                public privilegeService: PrivilegeService) {
        this.invalid = false;
    }

    agInit(params: ICellEditorParams): void {
        this.params = params;
        // console.log("Params", this.params.column.colId);
        if (this.params.column.colId === 'partner_id') {
            let c = this.params.errors();
            if (c instanceof Array) {
                c.filter((value) => {
                    if (value === 'partner_id') {
                        return this.invalid = true;
                    }
                });
            }
        }

        if (this.params.column.colId === 'restaurant_id') {
            let c = this.params.errors();
            if (c instanceof Array) {
                c.filter((value) => {
                    if (value === 'restaurant_id') {
                        return this.invalid = true;
                    }
                });
            }
        }

        if (this.params.column.colId === 'region_id') {
            let c = this.params.errors();
            if (c instanceof Array) {
                c.filter((value) => {
                    if (value === 'region_id') {
                        return this.invalid = true;
                    }
                });
            }
        }

        if (this.params.column.colId === 'city_id') {
            let c = this.params.errors();
            if (c instanceof Array) {
                c.filter((value) => {
                    if (value === 'city_id') {
                        return this.invalid = true;
                    }
                });
            }
        }

        if (this.params.column.colId === 'media_type_id') {
            let c = this.params.errors();
            if (c instanceof Array) {
                c.filter((value) => {
                    if (value === 'media_type_id') {
                        return this.invalid = true;
                    }
                });
            }
        }

        this.currentValue = (() => {
            return {
                value: null,
                label: params.value
            };
        })();
        // console.log(this.currentValue);
        // console.log("Params", this.params.search_params);
        if (this.params.search_params instanceof Array) {
            this.url = this.params.node.data.type_id !== 4 ? this.params.search_params[0] : this.params.search_params[1];
        } else {
            this.url = this.params.search_params;
        }
        this.privilege = this.params.privilege_params ? this.params.privilege_params : '';

        if (this.params.node.data.type_id === 4 && (this.params.column.colId === 'layout_type_id' || this.params.column.colId === 'layout_arrow' ||
                this.params.column.colId === "layout_distance" || this.params.column.colId === "layout_size" || this.params.column.colId === "layout_address" || this.params.column.colId === "layout_info")) {
            this.disabled = true;
        }

        if (this.params.node.data.type_id === 3 && this.params.column.colId === 'restaurant_id') {
            this.disabled = true;
        }
        if (this.params.node.data.type_id === 2 && this.params.column.colId === 'restaurant_id') {
            this.disabled = true;
        }
    }

    ngAfterViewInit() {
        if (this.privilegeService.privilegeAllowed([this.privilege])) {
            this.autocompleteElemRef.host.nativeElement.addEventListener('keydown', (event) => {
                event.stopPropagation();
            });
        }
    }

    getValue(): any {
        // console.log("GET VALUE PARAMS", this.params);
        if (this.params.column.colId === 'city_id') {
            const values = [];
            if (this.params.node.data.city.id == null) {
                values.push(this.params.node.data.city.name = this.currentValue.label);
                values.push(this.params.node.data.city.id = this.currentValue.value);
            }
            this.params.value = this.currentValue.value !== null ? this.currentValue.value : this.params.node.data.city.id;
            return values && this.params.value;
        }
        if (this.params.column.colId === 'region_id') {
            const values = [];
            if (this.params.node.data.region.id == null) {
                values.push(this.params.node.data.region.name = this.currentValue.label);
                values.push(this.params.node.data.region.id = this.currentValue.value);
            }
            this.params.value = this.currentValue.value !== null ? this.currentValue.value : this.params.node.data.region.id;
            return values && this.params.value;
        }
        if (this.params.column.colId === 'partner_id') {
            const values = [];
            if (this.params.node.data.partner.id == null) {
                values.push(this.params.node.data.partner.name = this.currentValue.label);
                values.push(this.params.node.data.partner.id = this.currentValue.value);
            }
            this.params.value = this.currentValue.value !== null ? this.currentValue.value : this.params.node.data.partner.id;
            return values && this.params.value;
        }
        if (this.params.column.colId === 'media_type_id') {
            const values = [];
            if (this.params.node.data.media_type.id == null) {
                values.push(this.params.node.data.media_type.name = this.currentValue.label);
                values.push(this.params.node.data.media_type.id = this.currentValue.value);
            }
            this.params.value = this.currentValue.value !== null ? this.currentValue.value : this.params.node.data.media_type.id;
            return values && this.params.value;
        }
        if (this.params.column.colId === 'address') {
            this.params.value = this.currentValue.label;
            return this.params.value;
        }
        if (this.params.column.colId === 'photo') {
            this.params.value = this.currentValue.label;
            return this.params.value;
        }
        // console.log("colId " + this.params.column.colId, this.params.value, this.params);
        if (this.params.node.data.type_id === 4 && this.params.column.colId === 'restaurant_id') {
            const values = [];
            if (!this.params.node.data.reach_program) {
                this.params.node.data.reach_program = {
                    id: null,
                    name: null,
                };
            }
            if (this.params.node.data.reach_program.id == null) {
                values.push(this.params.node.data.reach_program.name = this.currentValue.label);
                values.push(this.params.node.data.reach_program.id = this.params.value);
            }
            if (this.currentValue.value !== null) {
                this.params.node.data.reach_program.id = this.params.value;
            }
            this.params.value = this.currentValue.value !== null ? this.currentValue.value : this.params.node.data.reach_program.id;
            return values && this.params.value;
        }
        if (this.params.column.colId === 'restaurant_id') {
            const values = [];
            if (this.params.node.data.restaurant !== null && this.params.node.data.restaurant.id == null) {
                values.push(this.params.node.data.restaurant.eng_name = this.currentValue.label);
                values.push(this.params.node.data.restaurant.id = this.currentValue.value);
            }
            this.params.value = this.currentValue.value !== null ? this.currentValue.value : this.params.node.data.restaurant !== null ? this.params.node.data.restaurant.id : null;
            return values && this.params.value;
        }
        if (this.params.column.colId === 'media_type_id') {
            const values = [];
            if (this.params.node.data.media_type.id == null) {
                values.push(this.params.node.data.media_type.name = this.currentValue);
                values.push(this.params.node.data.media_type.id = this.params.value);
            }
            this.params.value = this.currentValue.value;
            return values && this.params.value;
        }
        if (this.params.node.data.type_id === 4 && this.params.column.colId === 'layout_type_id') {
            this.params.value = null;
            return this.params.value;
        }
        if (this.params.column.colId === 'layout_type_id') {
            const values = [];
            if (this.params.node.data.layout_type.id == null) {
                values.push(this.params.node.data.layout_type.name = this.currentValue.label);
                values.push(this.params.node.data.layout_type.id = this.params.value);
            }
            this.params.value = this.currentValue.value !== null ? this.currentValue.value : this.params.node.data.layout_type.id;
            // this.params.value = this.currentValue.value;
            return values && this.params.value;
        }
        if (this.params.column.colId === 'comment' || this.params.column.colId === 'layout_distance' || this.params.column.colId === 'layout_size' || this.params.column.colId === 'layout_address' || this.params.column.colId === 'layout_info' || this.params.column.colId === 'layout_arrow' || this.params.column.colId === 'layout_type') {
            this.params.value = this.currentValue.label;
            return this.params.value;
        }
        return this.params.value;
    }

    getItemList(request, response) {
        this.placementService.getAutoCompleteList(this.url, {search_string: request.term}).then((result) => {
            response(result);
        });
    }

    getSourceFunctionForAutocomplete() {
        return (request, response) => this.getItemList(request, response);
    }

    onAutocompleteSelect(event) {
        this.currentValue = event;
        this.params.value = this.currentValue.value;
    }
}
