import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
    selector: 'app-alert',
    templateUrl: './alert.component.html',
    styleUrls: ['./alert.component.less']
})
export class AlertComponent implements OnInit {

    private _message: string;
    @Input() title: string;

    @Input()
    set message(message: string | string[]) {
        if (message instanceof Array) {
            this._message = message.join('<br/>');
        } else {
            this._message = message;
        }
    }

    get message() {
        return this._message;
    }

    @Output() onClose = new EventEmitter();
    @Output() onConfirm = new EventEmitter();

    constructor() {
    }

    ngOnInit() {
    }

    reject() {
        this.onClose.emit();
    }

    confirm() {
        this.onConfirm.emit();
    }
}
