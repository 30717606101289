import { Privilege } from "../classes/privilege";
import { ApiService } from "../../system/services/api.service";
import * as _ from "lodash";
import * as i0 from "@angular/core";
import * as i1 from "../../system/services/api.service";
var PrivilegeService = /** @class */ (function () {
    function PrivilegeService(apiService) {
        this.apiService = apiService;
    }
    PrivilegeService.prototype.getAllowedPrivilegeList = function () {
        var _this = this;
        return this.apiService.request('/access/get_allowed_privilege_list')
            .then(function (privilegeSrcList) { return privilegeSrcList.map(function (privilegeSrc) { return _this.privilegeFromApi(privilegeSrc); }); });
    };
    PrivilegeService.prototype.loadAllowedPrivileges = function () {
        var _this = this;
        return this.isRootAccess()
            .then(function () {
            if (!_this.rootAccess) {
                if (_this.allowedPrivilegeList && _this.allowedPrivilegeList.length) {
                    return Promise.resolve(_this.allowedPrivilegeList);
                }
                return _this.getAllowedPrivilegeList()
                    .then(function (privilegeList) {
                    _this.allowedPrivilegeList = privilegeList;
                    _this.allowedPrivilegeListIndexedByAlias = _.keyBy(privilegeList, 'alias');
                });
            }
        });
    };
    PrivilegeService.prototype.isRootAccess = function () {
        var _this = this;
        if (this.rootAccess !== undefined) {
            return Promise.resolve(this.rootAccess);
        }
        return this.apiService.request('access/is_root_access')
            .then(function (rootAccess) {
            _this.rootAccess = !!rootAccess;
            return _this.rootAccess;
        });
    };
    PrivilegeService.prototype.privilegeAllowed = function (alias) {
        if (this.rootAccess) {
            return true;
        }
        if (!(alias instanceof Array)) {
            alias = [alias];
        }
        for (var i = 0; i < alias.length; i++) {
            if (this.allowedPrivilegeListIndexedByAlias[alias[i]] && this.allowedPrivilegeListIndexedByAlias[alias[i]] instanceof Privilege) {
                return true;
            }
        }
        return false;
    };
    PrivilegeService.prototype.getPrivilege = function (id) {
        var _this = this;
        return this.apiService.request('get_privilege', { id: id })
            .then(function (privilegeSrc) { return _this.privilegeFromApi(privilegeSrc); });
    };
    PrivilegeService.prototype.getPrivilegeList = function () {
        var _this = this;
        return this.apiService.request('access/get_privilege_list')
            .then(function (privilegeSrcList) {
            return privilegeSrcList.data.map(function (privilegeSrc) { return _this.privilegeFromApi(privilegeSrc); });
        });
    };
    PrivilegeService.prototype.createPrivilege = function (privilege) {
        var _this = this;
        return this.apiService.request('create_privilege', this.privilegeToApi(privilege))
            .then(function (privilegeSrc) { return _this.privilegeFromApi(privilegeSrc); });
    };
    PrivilegeService.prototype.updatePrivilege = function (privilege) {
        var _this = this;
        return this.apiService.request('update_privilege', this.privilegeToApi(privilege))
            .then(function (privilegeSrc) { return _this.privilegeFromApi(privilegeSrc); });
    };
    PrivilegeService.prototype.savePrivilege = function (privilege) {
        if (privilege.id > 0) {
            return this.updatePrivilege(privilege);
        }
        return this.createPrivilege(privilege);
    };
    PrivilegeService.prototype.deletePrivilege = function (id) {
        return this.apiService.request('delete_privilege', { id: id });
    };
    PrivilegeService.prototype.privilegeToApi = function (privilege) {
        var params = _.cloneDeep(privilege);
        return params;
    };
    PrivilegeService.prototype.privilegeFromApi = function (data) {
        return Privilege.create(data);
    };
    PrivilegeService.ngInjectableDef = i0.defineInjectable({ factory: function PrivilegeService_Factory() { return new PrivilegeService(i0.inject(i1.ApiService)); }, token: PrivilegeService, providedIn: "root" });
    return PrivilegeService;
}());
export { PrivilegeService };
