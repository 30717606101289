import { registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import localeRu from '@angular/common/locales/ru';
import { APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { CookieModule } from 'ngx-cookie';
import { ToastrModule } from 'ngx-toastr';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './components/app/app.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { LoaderModule } from './modules/core/controls/components/loader/loader.module';
import { WindowsHostModule } from './modules/core/controls/components/windows/windows-host.module';
import { ControlsModule } from './modules/core/controls/controls.module';
import { FilterService } from './modules/core/controls/services/filter.service';
import { SentryErrorHandler } from './modules/misc/sentry-error-handler';

registerLocaleData(localeRu, 'ru');

@NgModule({
    declarations: [
        AppComponent,
        PageNotFoundComponent,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        ReactiveFormsModule,
        HttpModule,
        HttpClientModule,
        CookieModule.forRoot(),
        ToastrModule.forRoot(),
        RouterModule,
        AppRoutingModule,
        LoaderModule,
        WindowsHostModule,
        ControlsModule,
    ],
    providers: [
        { provide: LOCALE_ID, useValue: 'ru' },
        { provide: ErrorHandler, useClass: SentryErrorHandler },
        { provide: APP_INITIALIZER, useFactory: (handler: SentryErrorHandler) => () => handler.init(), deps: [ErrorHandler], multi: true },
        FilterService,
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
