import {Component} from '@angular/core';
import {ICellEditorAngularComp} from "ag-grid-angular";
import {ICellEditorParams} from "ag-grid";
import {LayoutTypesService} from "../../../../references/layout-types/services/layout-types.service";
import {LayoutType} from "../../../../references/layout-types/classes/layout-type";
import {LayoutArrowsService} from "../../../../references/layout-arrows/services/layout-arrows.service";
import {LayoutArrow} from "../../../../references/layout-arrows/classes/layout-arrow";
import {BudgetType} from "../../../../references/budget-type/classes/budget-type";
import {BudgetTypeService} from "../../../../references/budget-type/services/budget-type.service";
import {PlacementsType} from "../../../classes/placements-type";
import {PlacementsService} from "../../../services/placements.service";
import {ContractorService} from "../../../../references/contractors/services/contractor.service";
import {Contractor} from "../../../../references/contractors/classes/contractor";
import {PrivilegeService} from "../../../../../core/access-control/services/privilege.service";

@Component({
  selector: 'app-cell-editor-listbox',
  templateUrl: './cell-editor-listbox.component.html',
  styleUrls: ['./cell-editor-listbox.component.less']
})
export class CellEditorListboxComponent implements ICellEditorAngularComp {

  public params: any;
  public layoutTypesList: LayoutType[];
  public layoutArrowsList: LayoutArrow[];
  public budgetTypeList: BudgetType[];
  public contractorList: Contractor[];
  public layout_type: any;
  public layout_arrow: any;
  public budget_type: any;
  public contractor_id: any;
  public invalid: boolean;

  constructor(private layoutTypesService: LayoutTypesService,
              private layoutArrowsService: LayoutArrowsService,
              private budgetTypeService: BudgetTypeService,
              private contractorService: ContractorService,
              public privilegeService: PrivilegeService) {
    this.invalid = false;
  }

  agInit(params: ICellEditorParams): void {
    this.params = params;
    if (this.params.column.colId === 'budget_type_id') {
      let c = this.params.errors();
      if (c instanceof Array) {
        c.filter((value) => {
          if (value === 'budget_type_id') {
            return this.invalid = true;
          }
        });
      }
    }
    const promises = [];
    promises.push(this.layoutArrowsService.getLayoutArrowsList().then((layoutArrowsList: LayoutArrow[]) => {
      this.layoutArrowsList = layoutArrowsList;
      this.layoutArrowsList.push({id: null, name: 'нет стрелки'});
    }));
    promises.push(this.layoutTypesService.getLayoutTypeList().then((layoutTypeList: LayoutType[]) => this.layoutTypesList = layoutTypeList));
    promises.push(this.budgetTypeService.getBudgetTypeList().then((budgetTypeList: BudgetType[]) => this.budgetTypeList = budgetTypeList));
    promises.push(this.contractorService.getContractorList().then((contractorList: Contractor[]) => this.contractorList = contractorList));
    Promise.all(promises).then(() => {
      this.budget_type = this.params.node.data.budget_type;
      this.layout_type = this.params.node.data.layout_type;
      this.layout_arrow = this.params.node.data.layout_arrow;
      this.contractor_id = this.params.node.data.contractor_id;
    });
  }

  getValue(): any {
    if (this.params.column.colId === 'budget_type_id') {
      this.params.value = this.budget_type.id;
      return this.budget_type.id;
    }
    if (this.params.column.colId === 'layout_arrow_id') {
      this.params.value = this.layout_arrow.id;
      return this.layout_arrow.id;
    }
    if (this.params.column.colId === 'layout_type_id') {
      this.params.value = this.layout_type.id;
      return this.layout_type.id;
    }

    if (this.params.column.colId === 'contractor_id') {
        this.params.value = this.contractor_id;
        return this.contractor_id;
    }
  }
}
