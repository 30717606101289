import { NgModule } from '@angular/core';
import { RouterModule, Routes } from "@angular/router";
import { PageNotFoundComponent } from "./components/page-not-found/page-not-found.component";
import { AuthenticationComponentGuard } from "./modules/core/authentication/guards/authentication-component.guard";

const coreRoutes: Routes = [
    {path: 'login', loadChildren: './modules/core/login/login.module#LoginModule'},
    {path: '', loadChildren: './modules/mi-kfc/mi-kfc.module#MiKfcModule', canLoad: [AuthenticationComponentGuard]},
    {path: '**', component: PageNotFoundComponent}
];

@NgModule({
    imports: [RouterModule.forRoot(coreRoutes)],
    exports: [RouterModule],
})
export class AppRoutingModule {
}
