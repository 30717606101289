import { Component } from '@angular/core';
import {ICellEditorAngularComp} from "ag-grid-angular";

@Component({
  selector: 'app-cell-renderer-routerlink',
  templateUrl: './cell-renderer-routerlink.component.html',
  styleUrls: ['./cell-renderer-routerlink.component.less']
})
export class CellRendererRouterlinkComponent implements ICellEditorAngularComp {

  private params: any;
  public name: string;
  public city_id: number[];
  public city_id_string: string;
  public year: number;

  constructor() { }

  agInit(params) {
    this.params = params;
    this.name = this.params.data.city_name ? this.params.data.city_name : null;
    if (this.params.data.city_id) {
      this.city_id = [];
      this.city_id.push(this.params.data.city_id);
      this.city_id_string = '[' + this.city_id.toString() + ']';

    } else {
      this.city_id = null;
    }
    this.year = this.params.year();
  }

  getValue() {

  }

}
