import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
    selector: 'app-window',
    templateUrl: './window.component.html',
    styleUrls: ['./window.component.less']
})
export class WindowComponent implements OnInit {
    @Input() title: string;
    @Input() iconCss: string;
    @Output('onClose') onClose = new EventEmitter();

    constructor() {
    }

    ngOnInit() {
    }

    public close() {
        this.onClose.emit();
    }
}
