var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FileInfo } from "./file-info";
var FileObject = /** @class */ (function (_super) {
    __extends(FileObject, _super);
    function FileObject() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    FileObject.create = function (data) {
        var fileObject = new FileObject();
        fileObject.name = data.name || data.filename;
        fileObject.size = data.size;
        fileObject.type = data.type;
        fileObject.content = data.content;
        return fileObject;
    };
    FileObject.createFromFile = function (file) {
        return new Promise(function (resolve, reject) {
            var reader = new FileReader();
            reader.onload = function (event) {
                var fileObject = {};
                fileObject.content = event.target.result.replace(/^.*;base64,/, '');
                fileObject.name = file.name;
                fileObject.size = file.size;
                fileObject.type = file.type;
                resolve(FileObject.create({
                    content: event.target.result.replace(/^.*;base64,/, ''),
                    name: file.name,
                    filename: file.name,
                    size: file.size,
                    type: file.type
                }));
            };
            reader.onerror = function (event) {
                reject(event);
            };
            reader.readAsDataURL(file);
        });
    };
    return FileObject;
}(FileInfo));
export { FileObject };
