import {Component, EventEmitter, HostBinding, Input, OnInit, Output} from '@angular/core';

@Component({
    selector: 'app-segment',
    templateUrl: './segment.component.html',
    styleUrls: ['./segment.component.less']
})
export class SegmentComponent implements OnInit {

    @Input() header: string;
    @Input() iconCss: string;
    @Input() headerCss: string;
    @Input() collapsable = false;
    @Input() @HostBinding('class.collapsed') collapsed = false;
    @Output() onHandlerClick = new EventEmitter;
    @Input() handlerCss: string;
    @Input() login = false; // added
    @Input() customCss = false; //added
    @Input() dataList: any[];
    @Output() onSelected = new EventEmitter;

    constructor() {
    }

    ngOnInit() {
    }

    toggleCollapse() {
        this.collapsed = !this.collapsed;
    }

    onSelectedData(id) {
      this.onSelected.emit(id);
    }
}
