import { NgModule } from '@angular/core';
import { NgSelectModule } from '@ng-select/ng-select';
import { ListboxComponent } from './components/listbox/listbox.component';
import { CommonModule } from "@angular/common";
import { AutocompleteComponent } from './components/autocomplete/autocomplete.component';
import { WindowsService } from "./components/windows/windows.service";
import { CheckboxComponent } from './components/checkbox/checkbox.component';
import { DatepickerComponent } from './components/datepicker/datepicker.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CheckboxService } from "./components/checkbox/checkbox.service";
import { RadiogroupComponent } from "./components/radiogroup/radiogroup.component";
import { SubmittedDirective } from "./directives/submitted.directive";
import { MaterialPlaceholderDirective } from "./directives/material-placeholder.directive";
import { TimepickerComponent } from './components/timepicker/timepicker.component';
import { TextMaskModule } from "angular2-text-mask";
import { SegmentComponent } from "./components/segment/segment.component";
import { LabelComponent } from "./components/label/label.component";
import { ButtonComponent } from "./components/button/button.component";
import { ProgressComponent } from "./components/progress/progress.component";
import { SelectAutocompleteComponent } from "./components/select-autocomplete/select-autocomplete.component";
import { ModalComponent } from "./components/modal/modal.component";
import { DataListComponent } from "./components/data-list/data-list.component";
import { MarkerComponent } from "./components/marker/marker.component";
import { PhotoComponent } from "./components/photo/photo.component";
import { PhotoGalleryComponent } from "./components/photo-gallery/photo-gallery.component";
import { PhotoViewerComponent } from "./components/photo-viewer/photo-viewer.component";
import { DialogComponent } from "./components/dialog/dialog.component";
import { TabMenuComponent } from "./components/tab-menu/tab-menu.component";
import { ItemsListComponent } from "./components/items-list/items-list.component";
import { AgGridRowActionsComponent } from "./components/ag-grid-row-actions/ag-grid-row-actions.component";
import { GroupboxComponent } from "./components/groupbox/groupbox.component";
import { WindowComponent } from "./components/window/window.component";
import { AgGridHtmlCellComponent } from "./components/ag-grid-html-cell/ag-grid-html-cell.component";
import { AgGridItemListComponent } from "./components/ag-grid-item-list/ag-grid-item-list.component";
import { DataTableComponent } from "./components/data-table/data-table.component";
import { AlertComponent } from "./components/alert/alert.component";
import { AgGridModule } from "ag-grid-angular";
import { PopupService } from "./services/popup.service";
import { AgGridConfigService } from "./services/ag-grid-config.service";
import { RouterModule } from "@angular/router";
import { PaginationComponent } from "./components/pagination/components/pagination.component";
import { PaginationStateService } from "./services/pagination-state.service";
import { RestrictComponent } from "./components/restrict/restrict.component";
import { ColorPickerModule } from "ngx-color-picker";
import { ChartDirective } from './directives/chart.directive';
import { IosHoveredFixDirective } from './directives/ios-hovered-fix.directive';
import { LoaderModule } from "./components/loader/loader.module";

@NgModule({
    imports: [
        RouterModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        TextMaskModule,
        LoaderModule,
        NgSelectModule,
        AgGridModule.withComponents([])
    ],
    declarations: [
        ListboxComponent,
        AutocompleteComponent,
        CheckboxComponent,
        DatepickerComponent,
        RadiogroupComponent,
        SubmittedDirective,
        MaterialPlaceholderDirective,
        TimepickerComponent,
        SegmentComponent,
        LabelComponent,
        ButtonComponent,
        ProgressComponent,
        SelectAutocompleteComponent,
        ModalComponent,
        DataListComponent,
        MarkerComponent,
        PhotoComponent,
        PhotoGalleryComponent,
        PhotoViewerComponent,
        DialogComponent,
        TabMenuComponent,
        ItemsListComponent,
        AgGridRowActionsComponent,
        GroupboxComponent,
        WindowComponent,
        AgGridHtmlCellComponent,
        AgGridItemListComponent,
        DataTableComponent,
        AlertComponent,
        PaginationComponent,
        RestrictComponent,
        ChartDirective,
        IosHoveredFixDirective
    ],
    exports: [
        ListboxComponent,
        AutocompleteComponent,
        CheckboxComponent,
        DatepickerComponent,
        RadiogroupComponent,
        SubmittedDirective,
        MaterialPlaceholderDirective,
        TimepickerComponent,
        SegmentComponent,
        LabelComponent,
        ButtonComponent,
        ProgressComponent,
        SelectAutocompleteComponent,
        ModalComponent,
        FormsModule,
        DataListComponent,
        MarkerComponent,
        PhotoComponent,
        PhotoGalleryComponent,
        PhotoViewerComponent,
        DialogComponent,
        TabMenuComponent,
        ItemsListComponent,
        AgGridRowActionsComponent,
        GroupboxComponent,
        WindowComponent,
        DataTableComponent,
        RouterModule,
        CommonModule,
        PaginationComponent,
        RestrictComponent,
        ColorPickerModule,
        ChartDirective,
        IosHoveredFixDirective,
        CommonModule,
        FormsModule,
        TextMaskModule,
        AgGridModule,
        LoaderModule,
    ],
    providers: [
        WindowsService,
        CheckboxService,
        PopupService,
        AgGridConfigService,
        PaginationStateService,
    ],
    entryComponents: [
        DialogComponent,
        AlertComponent,
        AgGridRowActionsComponent,
        AgGridHtmlCellComponent,
        AgGridItemListComponent,
    ]
})
export class ControlsModule {
}
