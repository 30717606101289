import {Component, ViewChild, ViewContainerRef} from '@angular/core';
import {IFilterAngularComp} from "ag-grid-angular";
import {IAfterGuiAttachedParams, IDoesFilterPassParams, IFilterParams, RowNode} from "ag-grid";
import {PlacementsService} from "../../../services/placements.service";
import {LoaderService} from "../../../../../core/system/services/loader.service";

@Component({
  selector: 'app-ag-custom-filter',
  templateUrl: './ag-custom-filter.component.html',
  styleUrls: ['./ag-custom-filter.component.less']
})
export class AgCustomFilterComponent implements IFilterAngularComp {
  private params: IFilterParams;
  private valueGetter: (rowNode: RowNode) => any;
  public applyButton: boolean;
  public text = '';
  public showFilterIcon: boolean;
  public filterListName: any[];
  public selectedItems: number[];

  constructor(private placementService: PlacementsService) {
    this.filterListName = [];
    this.selectedItems = [];
    this.showFilterIcon = false;
  }

  @ViewChild('input', {read: ViewContainerRef}) public input;

  agInit(params: IFilterParams): void {
    this.params = params;
    this.applyButton = params.applyButton;
    this.valueGetter = params.valueGetter;
    if (this.params.context) {
      // console.log(this.params.context);
      this.placementService.getFilterListName(this.params.context).then((listname) => {
        this.filterListName = listname;
      });
    }
    // console.log("Selected Items", this.selectedItems);
  }

  getGui() {
  }

  isFilterActive(): boolean {
    return this.showFilterIcon;
  }

  doesFilterPass(params: IDoesFilterPassParams): boolean {
    return this.text.toLowerCase()
      .split(" ")
      .every((filterWord) => {
        return this.valueGetter(params.node).toString().toLowerCase().indexOf(filterWord) >= 0;
      });
  }

  getModel(): any {
    return {value: this.text};
  }

  setModel(model: any): void {
    this.text = model ? model.value : '';
    this.selectedItems = [];
    this.showFilterIcon = false;
  }

  onChange(newValue): void {
    newValue.length === 0 ? this.showFilterIcon = false : this.showFilterIcon = true;
    if (this.text !== newValue) {
      this.text = newValue;
    }
  }

  save() {
    this.params.filterChangedCallback();
  }
}
