import {Component, HostBinding} from '@angular/core';
import {AgGridCellAutoHeight} from "../../classes/ag-grid-cell-auto-height";

@Component({
    selector: 'app-ag-grid-html-cell',
    templateUrl: './ag-grid-html-cell.component.html',
    styleUrls: ['./ag-grid-html-cell.component.less']
})
export class AgGridHtmlCellComponent extends AgGridCellAutoHeight {
    @HostBinding('innerHTML') htmlContent: string;
    protected params: any;

    public agInit(params) {
        super.agInit(params);
        this.htmlContent = params.value;
    }
}
