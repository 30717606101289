import { registerLocaleData } from '@angular/common';
import localeRu from '@angular/common/locales/ru';
import { SentryErrorHandler } from './modules/misc/sentry-error-handler';
registerLocaleData(localeRu, 'ru');
var ɵ0 = function (handler) { return function () { return handler.init(); }; };
var AppModule = /** @class */ (function () {
    function AppModule() {
    }
    return AppModule;
}());
export { AppModule };
export { ɵ0 };
