import {AfterViewInit, Component, ElementRef, ViewChild} from '@angular/core';
import {ICellEditorAngularComp} from "ag-grid-angular";
import {PrivilegeService} from "../../../../../core/access-control/services/privilege.service";
import {ICellEditorParams} from "ag-grid";


@Component({
  selector: 'app-cell-editor',
  templateUrl: './cell-editor.component.html',
  styleUrls: ['./cell-editor.component.less']
})
export class CellEditorComponent implements ICellEditorAngularComp, AfterViewInit {

  public params: any;
  public value: any;
  public disabled: boolean;
  public invalid: boolean;
  public privilege: string;
  @ViewChild('elemRef') elementRef: ElementRef;

  constructor(public privilegeService: PrivilegeService) {
    this.invalid = false;
  }

  agInit(params: ICellEditorParams) {
    this.params = params;
    this.value = this.params.value;
    this.privilege = this.params.privilege_params ? this.params.privilege_params : '';

    if (this.params.node.data.type_id === 4 && this.params.column.colId === 'opening_date') {
      this.disabled = true;
    }

    if (this.params.node.data.type_id === 3 && this.params.column.colId === 'opening_date' || this.params.column.colId === 'type' || this.params.column.colId === 'facts') {
      this.disabled = true;
    }
    if (this.params.node.data.type_id === 2 && this.params.column.colId === 'opening_date' || this.params.column.colId === 'type' || this.params.column.colId === 'facts') {
      this.disabled = true;
    }

    if (this.params.node.data.type_id === 1) {
      this.params.column.colId === 'facts' || this.params.column.colId === 'type' || this.params.column.colId === 'opening_date' ? this.disabled = true : this.disabled = null;
    }

    if (this.params.column.colId === 'type_id') {
      this.disabled = true;
    }

    if (this.params.column.colId === 'guid' && !this.privilegeService.privilegeAllowed(this.privilege)) {
        this.disabled = true;
    }
  }

  ngAfterViewInit() {
    this.elementRef.nativeElement.addEventListener('keydown', (event) => {
      event.stopPropagation();
    });
  }

  getValue(): any {
    if (this.params.column.colId === 'quantity') {
      this.params.value = parseFloat(this.value) || 0;
      return this.params.value;
    }
    if (this.params.column.colId === 'placement_price' && this.value !== null) {
      this.params.value = parseInt(this.value.replace(/\р./, '').replace(/\s/, ''), 10) || 0;
      // console.log("Value: ", this.value, "This.params.value: ", this.params.value);
      return this.params.value;
    }
    if (this.params.column.colId === 'production_price' && this.value !== null) {
      this.params.value = parseInt(this.value.replace(/\р./, '').replace(/\s/, ''), 10) || 0;
      // console.log("Value: ", this.value, "This.params.value: ", this.params.value);
      return this.params.value;
    }
    if (this.params.column.colId === 'comment') {
      this.params.value = this.value;
      return this.params.value;
    }
    if (this.params.column.colId === 'grp') {
      if (this.value && typeof this.value !== 'number') {
        this.params.value = parseFloat(this.value.replace(/\,/, '.'));
        return this.params.value;
      } else {
        this.params.value = typeof this.value === 'number' ? this.value : null;
      }
    }
    if (this.params.column.colId === 'type_id') {
      this.params.value = this.params.node.data.type_id;
      return this.params.node.data.type_id;
    }
    if (this.params.column.colId === 'guid') {
        this.params.value = this.value;
        return this.params.value;
    }
    return this.params.value;
  }

  onlyNumberKey(event) {
    if (this.params.column.colId !== 'grp') {
      return;
    }
    return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57 || event.charCode === 46 || event.charCode === 44;
  }
}
