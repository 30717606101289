/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app.component.less.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../modules/core/controls/components/loader/loader.component.ngfactory";
import * as i3 from "../../modules/core/controls/components/loader/loader.component";
import * as i4 from "@angular/router";
import * as i5 from "../../modules/core/controls/components/windows/windows-host.directive";
import * as i6 from "../../modules/core/controls/components/windows/windows.service";
import * as i7 from "@angular/common";
import * as i8 from "./app.component";
import * as i9 from "../../modules/core/system/services/loader.service";
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
function View_AppComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(0, null, null, 0))], null, null); }
function View_AppComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-loader", [["type", "fixed"], ["view", "dots"]], [[8, "className", 0]], null, null, i2.View_LoaderComponent_0, i2.RenderType_LoaderComponent)), i1.ɵdid(1, 114688, null, 0, i3.LoaderComponent, [], { view: [0, "view"], type: [1, "type"] }, null)], function (_ck, _v) { var currVal_1 = "dots"; var currVal_2 = "fixed"; _ck(_v, 1, 0, currVal_1, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).hostClass; _ck(_v, 0, 0, currVal_0); }); }
export function View_AppComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(1, 212992, null, 0, i4.RouterOutlet, [i4.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppComponent_1)), i1.ɵdid(3, 16384, null, 0, i5.WindowsHostDirective, [i6.WindowsInitializerService, i1.ViewContainerRef], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppComponent_2)), i1.ɵdid(5, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 1, 0); var currVal_0 = _co.loaderVisible; _ck(_v, 5, 0, currVal_0); }, null); }
export function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-root", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i1.ɵdid(1, 4308992, null, 0, i8.AppComponent, [i9.LoaderService, i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppComponentNgFactory = i1.ɵccf("app-root", i8.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
