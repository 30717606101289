import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../../../environments/environment';
import { TokenService } from '../../authentication/services/token.service';
import { FileObject } from '../../files/classes/file-object';
import { UploadedFile } from '../../files/classes/uploaded-file';
import { ApiError } from '../classes/api-error';

const serializeGet = function (obj) {
    const str = [];
    for (let prop in obj) {
        const value = obj[prop];
        if (typeof value === 'object') {
            if (Array.isArray(value)) {
                prop = prop + '[]';
                value.forEach(value => str.push(`${encodeURIComponent(prop)}=${encodeURIComponent(value)}`));
            } else if (value instanceof Date) {
                str.push(`${encodeURIComponent(prop)}=${value.toISOString()}`);
            }
        } else if (value) {
            str.push(`${encodeURIComponent(prop)}=${encodeURIComponent(value)}`);
        }
    }
    return str.join('&');
};

@Injectable({providedIn: 'root'})
export class ApiService {
    private noShowErrors = [
        3100
    ];

    protected static getFullUrl(path: string, url: string = environment.datapointUrl): string {
        let urlParts = [];
        let urlParams = {};
        urlParts.push(url.replace(/\/+$/, ''));
        urlParts.push(path.replace(/^\/+/, ''));
        if (environment.XDEBUG_SESSION_ID) {
            urlParams['XDEBUG_SESSION_START'] = environment.XDEBUG_SESSION_ID;
        }
        return urlParts.join('/') + ApiService.getUrlParamsString(urlParams);
    }

    public static getUrlParamsString(params: {}) {
        const result = serializeGet(params);
        return result.length
            ? '?' + result
            : '';
    }

    protected static parseDataByType(data, type) {
        switch (type) {
            case 'collection':
//                                return {data: data};
//                                break;
            case 'model':
            case 'chunked':
            case 'simple':
            default:
                return data;
        }
    }


    constructor(private http: HttpClient,
                protected tokenService: TokenService,
                private toastrService: ToastrService,
                private zone: NgZone) {
    }

    public request<T = any>(path: string, body = {}, method: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE' = 'POST'): Promise<T> {
        const methodHasBody = method === 'POST' || method === 'PUT' || method === 'PATCH';

        let headers = new HttpHeaders({
            'X-Requested-With': 'XMLHttpRequest',
        });

        const token = this.tokenService.getToken();
        if (token) {
            if (body instanceof FormData) {
                body.append('token', token);
            } else if (methodHasBody) {
                body['token'] = token;
            }
            headers = headers.append('X-Auth-Token', token);
        }
        return new Promise<any>((resolve, reject) => {
            const req$ = methodHasBody
                ? this.http.request(method, ApiService.getFullUrl(path), {body, headers})
                : this.http.request(method, ApiService.getFullUrl(path) + ApiService.getUrlParamsString(body), {headers});

            req$.subscribe((result: any) => {
                    if (result.status === 'success') {
                        let data = null;
                        if (result.data !== undefined) {
                            data = ApiService.parseDataByType(result.data, result.dataType || 'object');
                        }
                        return resolve(data);
                    } else if (result.status === 'error') {
                        if (result.error_code !== undefined && result.error_message !== undefined) {
                            return reject(new ApiError(parseInt(result.error_code, 10), result.error_message, result.data || null));
                        }
                    }
                    return reject();
                },
                error => {
                    return reject(error);
                });
        }).catch((error = null) => {
            if (error instanceof ApiError) {
                switch (error.code) {
                    case 1200:
                        this.tokenService.destroy();
                        break;
                    case 1100:
                        this.toastrService.warning(path, 'Доступ запрещён!');
                        break;
                    case 2000:
                        let errorMessage = '';
                        for (const key in error.data) {
                            if (error.data.hasOwnProperty(key)) {
                                errorMessage += error.data[key][0];
                                errorMessage += '<br>';
                            }
                        }
                        this.toastrService.error(errorMessage, error.message, {closeButton: true, enableHtml: true, timeOut: 30000});
                        break;
                    default:
                        if (error.code === 6103) {
                            this.toastrService.error(error.message, 'Ошибка');
                            setTimeout(() => {
                                this.tokenService.destroy();
                            }, 1500);
                        } else {
                            if (this.noShowErrors.indexOf(error.code) === -1) {
                                this.toastrService.error(error.message, 'Ошибка', {closeButton: true});
                            }
                        }
                }
            } else if (error instanceof HttpErrorResponse) {
                if (error.status === 0) {
                    this.toastrService.error('Ошибка подключения');
                }
            }
            return Promise.reject(error);
        });

    }

    public requestForDownload(path: string, params?): Promise<any> {
        params = params || {};
        const headers = new HttpHeaders({
            'Content-Type': 'application/json'
        });
        if (this.tokenService.getToken()) {
            if (params instanceof FormData) {
                params.append('token', this.tokenService.getToken());
            } else {
                params['token'] = this.tokenService.getToken();
            }
        }

        return new Promise((resolve, reject) => {
            return this.http.request('POST', ApiService.getFullUrl(path), {
                headers: headers,
                body: params,
                responseType: 'arraybuffer',
                observe: 'response'
            }).subscribe((response: any) => {
                const contentDispositionHeader = response.headers.get('Content-Disposition');
                if (contentDispositionHeader !== null) {
                    const blob = new Blob([new Uint8Array(response['body'])], {
                        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                    });
                    const filename = contentDispositionHeader.split(';')[1].trim().split('=')[1].replace(/\"/g, '');
                    const downloadUrl = URL.createObjectURL(blob);
                    let link = document.createElement('a');
                    link.href = downloadUrl;
                    link.download = filename;
                    document.body.appendChild(link);
                    link.click();

                    this.toastrService.success('Загрузка файла успешна', 'Успех');
                    resolve(blob);
                } else {
                    const decodeString = String.fromCharCode.apply(null, new Uint8Array(response['body']));
                    const json = JSON.parse(decodeString);
                    return reject(json);
                }
            });
        }).catch((err: any) => {
            if (err.error_code === 2000) {
                let errorMessage = '';
                for (const key in err.data) {
                    if (err.data.hasOwnProperty(key)) {
                        errorMessage += err.data[key][0];
                        errorMessage += '<br>';
                    }
                }
                this.toastrService.error(errorMessage, err.error_message, {enableHtml: true});
            }
        });
    }

    public requestForUpload(path: string,
                            file: File | FileObject,
                            progress: (event, totalSize, loadedSize) => any,
                            params?): Promise<UploadedFile> {
        params = params || {};
        let fileObjectPromise = null;
        if (file instanceof File) {
            fileObjectPromise = FileObject.createFromFile(file);
        } else {
            fileObjectPromise = file;
        }
        return Promise.resolve(fileObjectPromise)
            .then((fileObject: FileObject) => {
                return new Promise<UploadedFile>((resolve, reject) => {
                    const xhr = new XMLHttpRequest();

                    xhr.upload.onprogress = (event: any) => {
                        this.zone.run(() => {
                            progress(event, event.total, event.loaded);
                        });
                    };
                    xhr.onload = () => {
                        let result = JSON.parse(xhr.response);
                        if (result['status'] === 'success') {
                            result = result['data'];
                            const fileData = {
                                name: result['basename'] + '.' + result['extension'],
                                guid: result['guid'],
                                date: result['created_at'],
                                size: result['size'],
                                type: result['type'],
                            };
                            resolve(UploadedFile.create(fileData));
                        } else {
                            reject(result);
                        }
                    };
                    xhr.onerror = () => {
                        const result = JSON.parse(xhr.response);
                        console.log('error', result);

                        reject(result);
                    };
                    xhr.onabort = () => {
                        const result = JSON.parse(xhr.response);
                        console.log('abort', result);
                        reject(result);
                    };
                    xhr.open('post', ApiService.getFullUrl(path), true);
                    xhr.setRequestHeader('Content-Type', 'application/json');
                    xhr.send(JSON.stringify({
                        token: this.tokenService.getToken(),
                        file: fileObject,
                        ...params
                    }));
                });
            });
    }

}
