import * as moment from "moment";
import {environment} from "../../../../../environments/environment";

export class Helper {
    public static fromApiDateTime(date: string) {
        return moment(date, environment.DATETIME_FORMAT_API).isValid() ? moment(date, environment.DATETIME_FORMAT_API).toDate() : null;
    }

    public static toApiDateTime(date: Date) {
        if (date instanceof Date) {
            return moment(date).isValid() ? moment(date).format(environment.DATETIME_FORMAT_API) : null;
        }
        return null;
    }

    public static toApiDate(date: Date) {
        if (date instanceof Date) {
            return moment(date).isValid() ? moment(date).format(environment.DATE_FORMAT_API) : null;
        }
        return null;
    }

    public static intArray(arr) {
        return arr.filter((item) => {
            return item === '0' || item === 0 || parseInt(item, 10) > 0;
        });
    }
}
