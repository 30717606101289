import { Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { ICellEditorAngularComp } from "ag-grid-angular";
import { PrivilegeService } from "../../../../../core/access-control/services/privilege.service";

@Component({
  selector: 'app-cell-editor-checkbox',
  templateUrl: './cell-editor-presence.component.html',
  styleUrls: ['./cell-editor-presence.component.less']
})
export class CellEditorPresenceComponent implements ICellEditorAngularComp, OnInit, OnDestroy {
  public value: number;
  public privilege: string;
  public presenceList = [0, 1, 2].map(value => ({ value, label: value.toString(10) }));

  constructor(public privilegeService: PrivilegeService, private elementRef: ElementRef<HTMLElement>) {}

  ngOnInit() {
      this.elementRef.nativeElement.parentElement.style.padding = '0';
  }
  ngOnDestroy() {
      this.elementRef.nativeElement.parentElement.style.padding = null;
  }

    agInit(params: any): void {
    this.value = params.value;
    this.privilege = params.privilege_params ? params.privilege_params : '';
  }

  getValue() {
    return this.value;
  }
}
