import {Injectable} from '@angular/core';
import {Router, ActivatedRoute} from "@angular/router";
import {DateService} from "../../system/services/date.service";
import * as _ from "lodash";

@Injectable()
export class PaginationStateService {
  private paginationStates: any;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private dateService: DateService) {
    this.paginationStates = {};
  }

  private setPageFromUrl (hrefPrefix: string) {
    const urlParams = this.route.queryParams['value'];
    let urlParamsAreEmpty = true;
    for (const p in urlParams) {
      if (urlParams.hasOwnProperty(p)) {
        urlParamsAreEmpty = false;
      }
    }
    if (urlParams !== undefined && !urlParamsAreEmpty) {
      for (const prop in urlParams) {
        // set the filter model from url params
        if (urlParams.hasOwnProperty(prop) && prop.toString() === 'page') {
          this.paginationStates[hrefPrefix] = parseInt(urlParams[prop], 10);
          return parseInt(urlParams[prop], 10);
        }
      }
      return null;
    }
    return null;
  }

  private setPageFromGlobals (hrefPrefix: string) {
    console.log('Set page from globals')

    const urlParams = _.cloneDeep(this.route.queryParams['value']);
    urlParams['page'] = this.paginationStates[hrefPrefix];
    this.router.navigate([hrefPrefix], {queryParams: urlParams});
    return this.paginationStates[hrefPrefix];
  }

  public setPaginationState (hrefPrefix: string): Promise<number> {
    if (this.paginationStates[hrefPrefix] === undefined) {
      this.paginationStates[hrefPrefix] = {};
      this.paginationStates[hrefPrefix] = 1;
    } /*else {
      return new Promise((resolve, reject) => {
        return resolve (this.setPageFromGlobals(hrefPrefix));
      });
    }*/
    return new Promise((resolve, reject) => {
      if (!this.setPageFromUrl(hrefPrefix)) {
        return resolve (this.setPageFromGlobals(hrefPrefix));
      } else {
        return resolve (this.setPageFromUrl(hrefPrefix));
      }
    });

  }
  public updatePaginationState(pageNumber: number, hrefPrefix: string) {
    console.log('Update pagination state')
    const urlParams = _.cloneDeep(this.route.queryParams['value']);
    urlParams['page'] = pageNumber;
    this.router.navigate([hrefPrefix], {queryParams: urlParams});
    this.paginationStates[hrefPrefix] = pageNumber;
  }

}
